/*
 * @Description: 
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-14 19:10:40
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-24 17:30:46
 */ 
import React from 'react';
import TooltipComponent from '../../components/TooltipComponent'; // 文本溢出提示
// 列表查询数据
export const searchFormDataApply =(res)=> [ {
    label: '申请时间',
    name: 'applyTime',
    type: 'rangeTime',
}, {
    label: '状态',
    name: 'procStatus',  // 状态下拉
    type: 'statusSelect',
    dicGroup: "PROC_STATUS", // 类型参考最下边
    placeholder: '请选择',
}]
/**
 * @description: 表头信息
 * @author: 陈金金
 */
export const columns = [{
    title: '序号',
    width:100,
    render:(text,record,index)=> `${index+1}`,
},
{
    title: '申请人',
    dataIndex: 'applyName',
    key: 'applyName',
    width:130,
    render:t=>t?<TooltipComponent width={120}  text={t}/>:'-'
},
{
    title: '申请时间',
    key: 'applyTime',
    dataIndex: 'applyTime',
    width:180,
    render:t=>t?<TooltipComponent width={170}  text={t}/>:'-',
}, {
    title: '流程类型',
    key: 'procTypeStr',
    dataIndex: 'procTypeStr',
    width:120,
    render:t=>t?<TooltipComponent width={120}  text={t}/>:'-',
}, {
    title: '处理状态',
    key: 'operResultStr',
    dataIndex: 'operResultStr',
    width:130,
    render:t=>t?<TooltipComponent width={120}  text={t}/>:'-',
}]