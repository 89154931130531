/*
 * @Author: 陈金金
 * @Date: 2020-07-14 18:32:55
 * @LastEditTime: 2020-08-24 17:28:01
 * @LastEditors: 陈金金
 * @Description: 我的工作台列表
 * @FilePath: \BPO\src\pages\workbench\index.jsx
 */
import React, { Component } from "react";
import "./index.less";

import Search from "../../components/searchForm";
import Table from "../../components/table/index";
import ApprovalBtn from "./Components/ApprovalBtn";
import { columns, searchFormDataApply } from "./data";
class WorkbenchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      procStatus: "AUDIT",
      dataSource: [],
      procStatus: "pageProcTaskTodoByCondition",
      searchData: searchFormDataApply(),
      statusList: {},
      searchValue: {},
    };
  }
  componentDidMount() {
    this.queryTableList();
  }

  /**
   * @description: 列表数据请求
   * @author: 陈金金
   */

  queryTableList = (
    page = this.state.page,
    pageSize = this.state.pageSize
  ) => {
    const { procStatus, searchValue } = this.state;
    this.setState({ loading: true });
    this.$http[`${procStatus}`]({
      page,
      pageSize,
      ...searchValue,
    }).then((res) => {
      this.setState({ loading: false });
      if (res && res.errorCode == 200) {
        this.setState({
          dataSource: res.data.rows,
          total: res.data.total,
          page,
          pageSize,
        });
      }
    });
  };
  /**
   * @description: 我的工作台TAB切换
   * @author: 陈金金
   */
  hansleChangeTabs = (v) => {
    const { statusList } = this.state;
    if (v == "pageProcTaskDoneByList") {
      this.setState({
        searchData: searchFormDataApply(statusList),
      });
    }
    this.setState(
      {
        procStatus: this.getApi(v),
        dataSource: [],
        page: 1,
        pageSize: 10,
        searchValue: {},
      },
      () => {
        this.queryTableList();
      }
    );
  };

  /**
   * @description: 根据状态返回对应得接口名
   * @author: 陈金金
   */

  getApi = (type) => {
    let procStatus = "";
    switch (type) {
      case "AUDIT":
        procStatus = "pageProcTaskTodoByCondition";
        break;
      case "PASS":
        procStatus = "pageProcTaskDoneByCondition";
        break;
      default:
        procStatus = "pageProcTaskDoneByList";
        break;
    }
    return procStatus;
  };

  /**
   * @description: 表格操作
   * @param {type} type //流程类型
   * @param {type} procStatus //流程状态
   * @param {type} businessKey //业务流程编号
   * @return:
   * @author: 陈金金
   */

  action = () => {
    return {
      title: "操作",
      width: 100,
      key: "operation",
      fixed: "right",
      render: (t, r, i) => {
        return (
          <span className="operation-wrap">
            <ApprovalBtn
              key={`ApprovalBtn${i}`}
              type={r.procType}
              row={r}
              procStatus={r.procStatus}
              businessKey={r.businessKey}
              onOk={() => this.queryTableList()}
            />
          </span>
        );
      },
    };
  };
  /**
   * @description: 流程查询
   * @author: 陈金金
   */
  querySearch = (v) => {
    if (v && v.applyTime) {
      v.applyTimeEnd =
        v.applyTime[1].format("YYYY-MM-DD") + " 23:59:59";
      v.applyTimeStart =
        v.applyTime[0].format("YYYY-MM-DD") + " 00:00:00";
      delete v.applyTime;
    }
    this.setState(
      {
        searchValue: v,
        dataSource: [],
        page: 1,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      total,
      page,
      pageSize,
      dataSource,
      loading,
      searchData,
      procStatus,
    } = this.state;
    return (
      <div className="Workbench">
        <div className="account">
          <Search
            tabList={[
              { value: "待处理", key: "AUDIT" },
              { value: "已处理", key: "PASS" },
              { value: "流程查询", key: "全部" },
            ]}
            FormData={
              procStatus === "pageProcTaskDoneByList" &&
              searchData
            }
            hansleChangeTabs={(e) =>
              this.hansleChangeTabs(e)
            }
            handleSubmit={(v) => {
              this.querySearch(v);
            }}
          />
          <Table
            keyName={"businessKey"}
            columns={[...columns, this.action()]}
            dataSource={dataSource}
            scroll={{ x: "100%" }}
            loading={loading}
            total={total}
            current={page}
            pageSize={pageSize}
            isPagination={true}
            handleChangePage={this.queryTableList}
            handleChangePageSize={this.queryTableList}
          />
        </div>
      </div>
    );
  }
}

export default WorkbenchList;
