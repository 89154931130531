/*
 * @Description: 人员转项目申请
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 16:36:15
 */


import React, { Component } from 'react';
import { Button, Row, Col, Form, Modal, Radio, message } from 'antd';
import './Components.less'
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示
import TextFieldInput from '../../../components/sceneInput/TextAreaInput';
class StaffTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 3,
            visible: false,
            loading: false,
            btnLoading: false,
            pageNo: 1,
            pageSize: 10,
            isCard: false,
            searchValue: {},
            detailData: {},
            selectedRowKeys: []
        }
    }
    componentDidMount() {
        this.getDetail(this.props.businessKey)
    }
    /**
     * @description: 人员项目调整详情数据获取
     * @param {type} 
     * @return: 
     * @author: 陈金金
     */
    getDetail = (staffProjectAdjustId) => {
        this.setState({ Loading: true })
        this.$http.getStaffProjectAdjustById({ staffProjectAdjustId }).then(res => {
            this.setState({ Loading: false })
            if (res && res.errorCode == 200) {
                this.setState({
                    detailData: res.data,
                })
            }
        })
    }
    /**
     * @description:  人员项目调整流程审批
     * @param {type} Approval 1，通过，2 拒绝
     * @author: 陈金金
     */
    handleAddSure = () => {
        const { businessKey } = this.props;
        const { detailData } = this.state;
        this.props.form.validateFieldsAndScroll((err, v) => {
            this.setState({ btnLoading: true })
            this.$http[`${v.Approval == 'PASS' ? "staffProjectAdjustPass" : "staffProjectAdjustRefuse"}`]
                ({
                    staffProjectAdjustId: businessKey,
                    taskId: detailData.procStatusVO.taskId,
                    operDescription: v.operDescription
                }).then(res => {
                    this.setState({ btnLoading: false })
                    if (res && res.errorCode == 200) {
                        message.destroy();
                        message.success(res.message);
                        this.props.closeModal(true)
                    }
                })

        });
    }

    handleReturnStockBtnCancle = () => {
        this.props.form.resetFields();
        this.setState({ visible: false })
        this.props.closeModal()
    }
    /**
* @description: 自动回显数据
* @return:
*/
    renderDetailKeyHTML = () => {
        const { detailData } = this.state;
        return [
            { name: '调整人姓名：', key: 'staffName', span: 24 },
            { name: '调整人工号：', key: 'staffCode', span: 24 },
            { name: '调整人将转至：', key: 'projectNameAfter', span: 24 },
            { name: '调整原因说明', key: 'remarks', span: 24, block: true },
        ].map(item => {
            return (
                <Col span={item.span} key={item.key}>
                    <div className="detailbox">

                        <span className="label" style={{ display: item.block ? 'block' : 'inline-block' }}>{item.name}</span>
                        <div className="value" style={{ marginTop: item.block ? '10px' : '' }}>
                            {
                                detailData[item.key] ?
                                    <TooltipComponent
                                        text={detailData[item.key]}
                                        width={item.key == 'remarks'?420:300}
                                        line={item.key == 'remarks' ? 2 : undefined}
                                    /> : '--'

                            }

                        </div>
                    </div>
                </Col>
            );
        });
    };
    render() {
        const { visible, form, procStatus } = this.props;
        const { getFieldDecorator } = this.props.form
        const { btnLoading, detailData } = this.state;
        return (
            <>
                {
                    visible &&
                    <Modal
                        className="SalaryChangeModal modalInfo"
                        title="人员转项目申请"
                        visible={visible}
                        footer={procStatus != 'AUDIT' ? false : [
                            <div key="StaffTransfer">
                                <Button key="cancle" onClick={() => this.handleReturnStockBtnCancle()}>取消</Button>
                                <Button key="sure" onClick={this.handleAddSure} loading={btnLoading} type="primary" >提交 </Button>
                            </div>
                        ]}
                        width={480}
                        onCancel={this.handleReturnStockBtnCancle}
                        maskClosable={false}
                    >

                        <div className='modalTitle'><span>申请详情</span></div>
                        <Row className='bottom50'>
                            {this.renderDetailKeyHTML()}
                        </Row>
                        {this.isArray(detailData.logVOS) && detailData.logVOS.map((v, i) => {
                            return <div className="logVOSList bottom50" key={v.taskDefKey}>
                                <div className="Divider"></div>
                                <div className='modalTitle top20' >
                                    <span>
                                        {
                                            i == 0 && '一级审批'
                                        }{
                                            i == 1 && '二级审批'
                                        }{
                                            i == 2 && '三级审批'
                                        }
                                    </span>
                                </div>
                                <Form className="top20">
                                    <Form.Item >
                                        <Radio.Group defaultValue={v.operResult}>
                                            <Radio disabled={v.operResult != 'PASS'} value={'PASS'}>同意</Radio>
                                            <Radio disabled={v.operResult != 'REFUSE'} value={'REFUSE'}>不同意</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="备注">
                                        <TooltipComponent
                                            text={v.operDescription}
                                            line={2}
                                            width={400}
                                        />
                                    </Form.Item>

                                </Form>
                            </div>
                        })
                        }


                        {
                            procStatus == 'AUDIT' && <div>
                                <div className="Divider"></div>
                                <div className='modalTitle top20'>
                                    <span>
                                        {
                                            this.isArray(detailData.logVOS) && detailData.logVOS.length == 1 && '二级审批'
                                        }
                                        {
                                            this.isArray(detailData.logVOS) && detailData.logVOS.length == 2 && '三级审批'
                                        }
                                        {
                                            detailData.logVOS && detailData.logVOS.length == 0 && '审批意见'
                                        }
                                    </span>
                                </div>
                                <Form className="top20">
                                    <Form.Item >
                                        {getFieldDecorator('Approval', {
                                            initialValue: 'PASS',
                                        })(
                                            <Radio.Group>
                                                <Radio value={'PASS'}>同意</Radio>
                                                <Radio value={'REFUSE'}>不同意</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    <TextFieldInput
                                        form={form}
                                        showLength={true}
                                        label="备注"
                                        placeholder="请输入"
                                        allowClear
                                        field="operDescription"
                                        maxLength={500}
                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                    />
                                </Form>
                            </div>

                        }


                    </Modal>
                }
            </>
        );
    }
}

export default Form.create()(StaffTransfer);