/*
 * @Author: xiajikun
 * @Date: 2020-04-24 09:56:43
 * @Last Modified by:
 * @Last Modified time: 2020-06-03 15:20:12
 */
import React from "react";
import { Input, Form } from "antd";
import { isTextFieldInput } from "../../utils/validate"; // 引用校验规则
const { TextArea } = Input;
class TextAreaInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0,
    };
  }

  componentDidMount() {}
  /**
   * @value 字段值
   *
   * @memberof textFieldInput
   */
  validator = (rules, value, callback) => {
    const { label, minLength } = this.props;
    if (value) {
      if (isTextFieldInput(value.trim(), minLength)) {
        callback();
      } else {
        callback(`内容长度不得小于${minLength}字符`);
      }
    } else {
      callback();
    }
  };
  getNum = (event) => {
    let value = event.target.value;
    const { field, form } = this.props;
    if (value == "") {
      form.setFields({
        [`${field}`]: {
          value: undefined,
        },
      });
    }
    this.setState({
      num: value.length > 500 || value.length == 500 ? 500 : value.length,
    });
  };
  /**
   * 多行输入框组件
   * @props的属性
   * label 标题
   * required 是否必填
   * field 字段名
   * trigger 校验的时机
   * showLength 显示最大长度
   */
  render() {
    const { num } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      label,
      required,
      field,
      trigger,
      initialValue,
      maxLength,
      showLength,
    } = this.props;
    const propsObj = {
      ...this.props,
    };

    Reflect.deleteProperty(propsObj, "showLength");
    return (
      <Form.Item label={label}>
        {getFieldDecorator(field, {
          initialValue: initialValue || null,
          validate: [
            {
              trigger: trigger || "onChange",
              rules: [
                {
                  required,
                  message: `请输入${label}`,
                },
                {
                  validator: this.validator,
                },
              ],
            },
          ],
        })(
          <div style={{ position: "relative" }}>
            <TextArea {...propsObj} onChange={(e) => this.getNum(e)} />
            {showLength && (
              <span
                style={{
                  color: "#ADB7CC",
                  position: "absolute",
                  bottom: "-14%",
                  right: "2%",
                }}
              >
                {num}/{maxLength}
              </span>
            )}
          </div>
        )}
      </Form.Item>
    );
  }
}

export default TextAreaInput;
