/*
 * @Description: 审批按钮
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-08-03 14:24:37
 */

import React, { Component } from "react";
import "./Components.less";
import SalaryChange from "./SalaryChange"; //薪资调整
import EndProject from "./EndProject"; //结项申请
import BasicInfoChange from "./BasicInfoChange"; //人员基础信息调整
import ProjectTransfer from "./ProjectTransfer"; //项目转移
import StaffTransfer from "./StaffTransfer"; //人员转项目
import UpperLimit from "./UpperLimit"; //薪资超上限通知

class ApprovalBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      selectedRowKeys: [],
    };
  }

  handleReturnStockBtnCancle = (e) => {
    if (e) this.props.onOk();
    this.setState({ visible: false });
  };
  /**
   * @description: 弹窗显示类型判断
   * @param {type} type //流程类型
   * @param {type} procStatus //流程状态
   * @param {type} businessKey //业务流程编号
   * @param {type} visible //模态框显隐
   * @return:
   * @author: 陈金金
   */
  showModal = (type) => {
    const { visible } = this.state;
    const { businessKey, procStatus, row } = this.props;
    let modalItem = null;
    switch (type) {
      case "SalaryAdjust":
        modalItem = (
          <SalaryChange
            businessKey={businessKey}
            visible={visible}
            procStatus={procStatus}
            closeModal={this.handleReturnStockBtnCancle}
          />
        );
        break;
      case "PostProject":
        modalItem = (
          <EndProject
            visible={visible}
            procStatus={procStatus}
            businessKey={businessKey}
            closeModal={this.handleReturnStockBtnCancle}
          />
        );
        break;
      case "StaffInfoAdjust":
        modalItem = (
          <BasicInfoChange
            visible={visible}
            procStatus={procStatus}
            businessKey={businessKey}
            closeModal={this.handleReturnStockBtnCancle}
          />
        );
        break;
      case "ProjectAdjust":
        modalItem = (
          <ProjectTransfer
            visible={visible}
            procStatus={procStatus}
            businessKey={businessKey}
            row={row}
            closeModal={this.handleReturnStockBtnCancle}
          />
        );
        break;
      case "StaffProjectAdjust":
        modalItem = (
          <StaffTransfer
            visible={visible}
            procStatus={procStatus}
            businessKey={businessKey}
            closeModal={this.handleReturnStockBtnCancle}
          />
        );
        break;
      case "EmolumentInform":
        modalItem = (
          <UpperLimit
            visible={visible}
            procStatus={procStatus}
            businessKey={businessKey}
            closeModal={this.handleReturnStockBtnCancle}
          />
        );
        break;
      default:
        break;
    }
    return modalItem;
  };
  render() {
    const { visible } = this.state;
    const { type, procStatus } = this.props;
    return (
      <>
        <span className="" onClick={() => this.setState({ visible: true })}>
          {procStatus == "AUDIT" ? "审批" : "查看"}
        </span>
        {visible && this.showModal(type)}
      </>
    );
  }
}

export default ApprovalBtn;
