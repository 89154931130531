import React, { Component } from 'react'
import { Layout, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import Logout from './logout'
import '../component.less'
import logo from '../../assets/image/logo.png';
import Initavatars from '../../assets/image/Initavatars.png';
import utils from '../../utils/utils';
import history from '../../api/history';

const { Header } = Layout;


class HeaderComponent extends Component {
  // refreshType : true , // 刷新当前页面
  // false // 跳转登录页面，满足从邮箱进入直接回到系统主页
  handleRefresh = () => {
    const { refreshType } = this.props
    if (refreshType) {
      window.location.reload() 
    }else{
        let token = utils.get('userInfo');
        if (token) {
          window.location.href = window.location.origin + "/IdleAssets/IdleAssetsList"
        }else{
          history.push('/');
        }
    }
  }
  render() {
    const { isUserShow } = this.props;

    return (
      <Layout className="layout" theme="light">
        <div className="HeaderComponent">
          <Header>
            <div className="headerbox">
              <div className="logo" onClick={this.handleRefresh}>
                <div><img src={logo} alt="" /></div>
                <div className="logotext">BPO生态看板系统</div>
              </div>
              {!isUserShow &&
                <div className="center">
                  <Dropdown overlay={Logout(this.props)} trigger={['click']} >
                    <div className="portrait">
                      <div><img src={Initavatars} alt="" /></div>
                      <div className="span">{utils.get('userInfo').staffName}</div>
                      <div><CaretDownOutlined /></div>
                    </div>
                  </Dropdown>
                </div>
              }
            </div>
          </Header>
        </div>
      </Layout>

    );
  }
}


export default HeaderComponent;
