/**
 * 此组件与 BaseInput 区别在于：可以显示当前输入的字符长度和最大长度
 */
import React from "react";
import BaseInput from "../baseInput";
class BaseInput2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curNum: 0,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      const { initialValue } = nextProps;
      const defaultCurNum = (initialValue && initialValue.length) || 0;
      this.setCurNum(defaultCurNum);
    }
  }
  setCurNum = (value) => {
    this.setState({ curNum: value });
  };
  render() {
    let { maxLength = 100, ...rest } = this.props;
    const { curNum } = this.state;
    console.log("curNum --->", curNum);
    return (
      <BaseInput
        {...rest}
        maxLength={maxLength}
        addonAfter={
          <span>
            <span
              style={{
                color: curNum > maxLength && "rgba(0, 0, 0, 0.65)",
              }}
            >
              {curNum}
            </span>
            {` / ${maxLength}`}
          </span>
        }
        onChange={(e) => this.setCurNum(e.target.value.length)}
      />
    );
  }
}

export default BaseInput2;
