/*
 * @Description: 薪资超上限通知
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 14:05:44
 */

import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  Modal,
  Radio,
  message,
  Spin,
} from "antd";
import "./Components.less";
import TextFieldInput from "../../../components/sceneInput/TextAreaInput";
import TooltipComponent from "../../../components/TooltipComponent"; // 文本溢出提示

class UpperLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailKey: [
        { name: "姓名：", key: "staffName", span: 24 },
        { name: "工号：", key: "staffCode", span: 24 },
        { name: "入职时间：", key: "onBoardingTime", span: 24 },
        { name: "博彦司龄：", key: "beyondWorkingAge", span: 12 },
        { name: "性别：", key: "sexStr", span: 12 },
        { name: "联系电话：", key: "contactNumber", span: 12 },
        { name: "身份证号码：", key: "idNumber", span: 12 },
        { name: "组别：", key: "groupName", span: 12 },
        { name: "WBS：", key: "wbsName", span: 12 },
        { name: "人员身份：", key: "identityStr", span: 12 },
        { name: "付费类型：", key: "payTypeStr", span: 12 },
        { name: "办公城市：", key: "cityName", span: 12 },
        { name: "服务性质：", key: "serveNatureName", span: 12 },
        { name: "层级：", key: "rank", span: 12 },
        { name: "社保公积金成本：", key: "socialSecurityFundCost", span: 12 },
        { name: "采购价：", key: "purchasePrice", span: 12 },
        { name: "转正系统薪资：", key: "turnSalary", span: 12 },
        // { name: "绩效考核基准：", key: "performanceStandard", span: 12 },
        {
          name: "非系统固定涨薪：",
          key: "nonSystemFixedSalaryIncrease",
          span: 12,
        },
        {
          name: "个人绩效基准值：",
          key: "personalPerformanceBenchmarks",
          span: 12,
        },
        {
          name: "项目绩效池基准值：",
          key: "projectPerformancePoolBenchmarks",
          span: 12,
        },

        { name: "薪资合计：", key: "salaryTotal", span: 12 },
        { name: "薪资上限参考值：", key: "purchaseSalaryCeil", span: 12 },
        { name: "超上限备注说明：", key: "remarks", span: 12 },
      ],
      btnLoading: false,
      visible: false,
      Loading: false,
      detailData: {},
    };
  }
  componentDidMount() {
    this.getDetail(this.props.businessKey);
  }
  /**
   * @description: 薪资超上限详情数据获取
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getDetail = (emolumentInformId) => {
    this.setState({ Loading: true });
    this.$http.getEmolumentInformById({ emolumentInformId }).then((res) => {
      this.setState({ Loading: false });
      if (res && res.errorCode == 200) {
        console.log("res --->", res);
        this.setState({
          detailData: res.data,
        });
      }
    });
  };
  /**
   * @description:  薪资超上限流程审批
   * @param {type} Approval 1，通过，2 拒绝
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { businessKey } = this.props;
    const { detailData } = this.state;
    this.props.form.validateFieldsAndScroll((err, v) => {
      this.setState({ btnLoading: true });
      this.$http
        .emolumentInformPass({
          emolumentInformId: businessKey,
          taskId: detailData.procStatusVO.taskId,
          operDescription: v.operDescription,
        })
        .then((res) => {
          this.setState({ btnLoading: false });
          if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.closeModal(true);
          }
        });
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
    this.props.closeModal();
  };
  /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData, detailKey } = this.state;
    const parmas = {
      ...detailData,
      ...detailData.rosterVO,
    };
    return detailKey.map((item) => {
      return (
        <Col span={8} key={item.key}>
          <div className="detailbox">
            <span className="label">{item.name}</span>
            <div className="value">
              {parmas[item.key] ? (
                <TooltipComponent text={parmas[item.key]} width={110} />
              ) : (
                "--"
              )}
            </div>
          </div>
        </Col>
      );
    });
  };
  render() {
    const { visible, procStatus, form } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { Loading, btnLoading, detailData } = this.state;
    return (
      <>
        {visible && (
          <Modal
            className="SalaryChangeModal modalInfo"
            title="薪资超上限通知"
            visible={visible}
            width={800}
            footer={
              procStatus != "AUDIT"
                ? false
                : [
                    <div key="UpperLimit">
                      <Button
                        key="cancle"
                        onClick={() => this.handleReturnStockBtnCancle()}
                      >
                        取消
                      </Button>
                      <Button
                        key="sure"
                        onClick={this.handleAddSure}
                        loading={btnLoading}
                        type="primary"
                      >
                        提交{" "}
                      </Button>
                    </div>,
                  ]
            }
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
          >
            <Spin spinning={Loading}>
              <div className="modalTitle">
                <span>申请详情</span>
              </div>
              <Row>{this.renderDetailKeyHTML()}</Row>
            </Spin>
            <div className="Divider"></div>

            <Form>
              <div className="modalTitle">
                <span>审批意见</span>
              </div>
              <Form.Item>
                {getFieldDecorator("Approval", {
                  initialValue: "PASS",
                })(
                  <Radio.Group>
                    <Radio value={"PASS"}>知悉</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {procStatus == "AUDIT" ? (
                <TextFieldInput
                  form={form}
                  showLength={true}
                  label="备注"
                  placeholder="请输入"
                  allowClear
                  field="operDescription"
                  maxLength={500}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              ) : (
                <Form.Item label="备注">
                  <TooltipComponent
                    text={
                      detailData &&
                      this.isArray(detailData.logVOS) &&
                      detailData.logVOS[0].operDescription
                    }
                    line={2}
                    width={730}
                  />
                </Form.Item>
              )}
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(UpperLimit);
