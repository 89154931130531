import React, { Component } from "react";
import { Layout, Breadcrumb } from "antd";
import _ from "lodash";

import { isArray } from "../../utils/common";

const { Content } = Layout;
class BreadcrumbLists extends Component {
  state = {
    breadName: "", //面包屑title
    paramsList: [], //若传参
  };

  componentDidMount() {
    this.queryBreadName();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.location &&
      this.props.location.pathname != nextProps.location &&
      nextProps.location.pathname
    ) {
      this.queryBreadName(nextProps.location.pathname);
    }
  }

  /* @方法描述:根据当前路径返回name
   * @return :name
   */

  queryBreadName = (name) => {
    const { children, location } = this.props;
    const { paramsList } = this.state;
    const breadName = name ? name : location && location.pathname;

    if (isArray(children)) {
      children.map((item) => {
        if (item.props.path == breadName) {
          if (location && location.search != "") {
            paramsList.push({
              path: location.pathname,
              search: location && location.search,
            });
          }

          this.setState({
            breadName: item.props.name,
            paramsList: _.unionBy(paramsList, "path"),
          });
        }
      });
    }
  };

  /* @方法描述:跳转对应路径
   * @return :{String}  路径
   */
  linkToPage = (index) => {
    const { breadName, paramsList } = this.state;
    const BreadcrumbList = breadName
      ? JSON.parse(JSON.stringify(breadName.split("-")))
      : [];
    const toBreadList = BreadcrumbList.slice(0, index + 1);
    const bread = toBreadList.join("-");
    const { children } = this.props;
    if (isArray(children)) {
      children.map((item) => {
        if (item.props.name == bread) {
          if (isArray(paramsList)) {
            if (
              _.filter(paramsList, (obj) => obj.path == item.props.path)
                .length == 0
            ) {
              this.props.history.push(item.props.path);
              return false;
            } else {
              paramsList.map((ite) => {
                if (ite.path == item.props.path) {
                  this.props.history.push(`${item.props.path}${ite.search}`);
                  return false;
                }
              });
            }
          } else {
            this.props.history.push(item.props.path);
          }
        }
      });
    }
  };

  render() {
    const { breadName } = this.state;

    const BreadcrumbList = breadName
      ? JSON.parse(JSON.stringify(breadName.split("-")))
      : [];

    return (
      <Layout className="layout">
        {breadName != "" && (
          <Content>
            <div className="bread">
              <Breadcrumb>
                {BreadcrumbList.map((item, i) => {
                  return (
                    <Breadcrumb.Item key={item}>
                      {i > 0 && i < BreadcrumbList.length - 1 ? (
                        <font onClick={() => this.linkToPage(i)}>{item} </font>
                      ) : (
                        item
                      )}
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            </div>
          </Content>
        )}
      </Layout>
    );
  }
}

export default BreadcrumbLists;
