/*
 * @文档描述: 枚举类型下拉框
 * @author: 西园
 * @Date: 2020-06-07 15:48:02
 * @LastEditTime: 2020-07-26 11:35:22
 * @LastEditors: 陈金金
 * dicGroup： 枚举类型 statusPropsList同时使用
 * statusPropsList： 自定义枚举数据  不可与dicGroup同时使用
 * 新加方法：
        handleChange={value => {
            console.log(value);
        }}
 */
import React from "react";
import { Form, Select } from "antd";
const { Option } = Select;

class StatusSelect extends React.Component {
  state = {
    statusList: [],
  };
  componentDidMount() {
    const { dicGroup, statusPropsList } = this.props;
    if (dicGroup && !statusPropsList) {
      // 判断是否有自定义
      this.queryDropStatus();
    }
  }
  /**
   * @函数描述: 查询状态下拉
   * @param {String} RETURN_WAYS 归还方式,
   * @param {String} RETURN_TYPE 归还类型,
   * @param {String} RETURN_STATUS 归还状态,
   * @param {String} INVENTORY_RESULT 盘点结果,
   * @param {String} ASSETS_STATUS 资产状态,
   * @param {String} FREE_STOCK_STATUS 易耗品状态,
   * @param {String} PROC_STATUS 流程状态,
   * @param {String} PURCHASE_APPLY_SUM 低值易耗品金额限制,
   * @return:
   */
  queryDropStatus = () => {
    const { dicGroup } = this.props;
    this.$http.queryDicCacheList([dicGroup]).then((res) => {
      if (res && res.errorCode === 200) {
        let arr = [];
        if (res.data && res.data[dicGroup]) arr = res.data[dicGroup];
        this.setState({
          statusList: arr,
        });
      } else {
        this.setState({
          statusList: [],
        });
      }
    });
  };
  handleModeChange = (value) => {
    this.props.handleChange && this.props.handleChange(value);
    return value;
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      label,
      required,
      message,
      initialValue,
      field,
      statusPropsList,
      dicGroup,
    } = this.props;
    const statusList =
      dicGroup && !statusPropsList ? this.state.statusList : statusPropsList;
    return (
      <div className="status-select">
        <Form.Item label={label}>
          {getFieldDecorator(field, {
            initialValue: initialValue || undefined,
            getValueFromEvent: this.handleModeChange,
            validate: [
              {
                trigger: "onChange",
                rules: [{ required, message: `请选择${label || message}` }],
              },
            ],
          })(
            <Select placeholder="请选择" allowClear>
              {statusList.map((item) => {
                return <Option key={item.key}>{item.value}</Option>;
              })}
            </Select>
          )}
        </Form.Item>
      </div>
    );
  }
}

export default StatusSelect;
