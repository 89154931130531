
import Idle from '../assets/image/idle.png';
import Inventory from '../assets/image/Inventory.png';
import Assfer from '../assets/image/assfer.png';
import Collect from '../assets/image/collect.png';
import Lease from '../assets/image/lease.png';
import LeaseMan from '../assets/image/leaseMan.png';
import Low from '../assets/image/low.png';
import Monitor from '../assets/image/monitor.png';
import Return from '../assets/image/return.png';
import System from '../assets/image/system.png';

export const systemIcon=[
    {
        icon:Idle,
        name:'闲置资产管理'
    },{
        icon:Inventory,
        name:'资产盘点记录'
    },{
        icon:Assfer,
        name:'租赁资产转移'
    },{
        icon:Collect,
        name:'领用登记列表'
    },{
        icon:LeaseMan,
        name:'租赁管理'
    },{
        icon:Lease,
        name:'租赁资产管理'
    },{
        icon:Low,
        name:'低值易耗品管理'
    },{
        icon:Monitor,
        name:'监控系统'
    },{
        icon:Return,
        name:'归还登记表'
    },{
        icon:System,
        name:'系统设置'
    },
]




