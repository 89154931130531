/*
 * @Description: 项目转移申请
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 16:20:11
 */

import React, { Component } from "react";
import { message, Row, Col, Form, Modal, Radio, Button } from "antd";
import "./Components.less";
import TooltipComponent from "../../../components/TooltipComponent"; // 文本溢出提示
import TextFieldInput from "../../../components/sceneInput/TextAreaInput";
class ProjectTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      isCard: false,
      searchValue: {},
      detailData: {},
      selectedRowKeys: [],
    };
  }
  componentDidMount() {
    this.getDetail(this.props.businessKey);
  }
  /**
   * @description: 项目调整详情数据获取
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getDetail = (projectAdjustId) => {
    const { row, projectId } = this.props;
    this.setState({ Loading: true });
    this.$http.getProjectAdjustById({ projectAdjustId }).then((res) => {
      this.setState({ Loading: false });
      if (res && res.errorCode == 200) {
        this.setState({
          detailData: res.data,
        });
      }
    });
  };
  /**
   * @description:  项目迁移流程审批
   * @param {type} Approval 1，通过，2 拒绝
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { businessKey } = this.props;
    const { detailData } = this.state;
    this.props.form.validateFieldsAndScroll((err, v) => {
      this.setState({ btnLoading: true });
      this.$http[
        `${
          v.Approval == "PASS" ? "projectAdjustIdPass" : "projectAdjustIdRefuse"
        }`
      ]({
        projectAdjustId: businessKey,
        taskId: detailData.procStatusVO.taskId,
        operDescription: v.operDescription,
      }).then((res) => {
        this.setState({ btnLoading: false });
        if (res && res.errorCode == 200) {
          message.destroy();
          message.success(res.message);
          this.props.closeModal(true);
        }
      });
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
    this.props.closeModal();
  };
  /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { row } = this.props;
    const { detailData } = this.state;
    const parmas = {
      ...detailData,
      // ...detailData.logVOS
    };
    return [
      { name: "项目名称：", key: "projectName", span: 24 },
      { name: "项目类型：", key: "projectTypeStr", span: 24 },
      { name: "业务类型：", key: "businessTypeStr", span: 24 },
      { name: "项目交接组：", key: "handoverAfterGroupId", span: 24 },
      { name: "项目转移原因说明：", key: "remarks", span: 24, block: true },
    ].map((item) => {
      return (
        <Col span={item.span} key={item.key}>
          <div className="detailbox">
            <span
              className="label"
              style={{ display: item.block ? "block" : "inline-block" }}
            >
              {item.name}
            </span>
            <div
              className="value"
              style={{ marginTop: item.block ? "10px" : "" }}
            >
              {parmas[item.key] ? (
                <TooltipComponent
                  text={parmas[item.key]}
                  line={item.key == "remarks" ? 2 : undefined}
                  width={item.block ? 400 : 300}
                />
              ) : (
                "--"
              )}
            </div>
          </div>
        </Col>
      );
    });
  };
  render() {
    const { visible, procStatus, form } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { btnLoading, detailData } = this.state;
    return (
      <>
        {visible && (
          <Modal
            className="SalaryChangeModal modalInfo"
            title="项目转移申请"
            visible={visible}
            width={480}
            footer={
              procStatus != "AUDIT"
                ? false
                : [
                    <div key="ProjectTransfer">
                      <Button
                        key="cancle"
                        onClick={() => this.handleReturnStockBtnCancle()}
                      >
                        取消
                      </Button>
                      <Button
                        key="sure"
                        onClick={this.handleAddSure}
                        loading={btnLoading}
                        type="primary"
                      >
                        提交{" "}
                      </Button>
                    </div>,
                  ]
            }
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
          >
            <div className="modalTitle">
              <span>申请详情</span>
            </div>
            <Row className="bottom50">{this.renderDetailKeyHTML()}</Row>
            {this.isArray(detailData.logVOS) &&
              detailData.logVOS.map((v, i) => {
                return (
                  <div className="logVOSList top50" key={i}>
                    <div className="Divider"></div>
                    <div className="modalTitle top20">
                      <span>
                        {i == 0 && "一级审批"}
                        {i == 1 && "二级审批"}
                      </span>
                    </div>
                    <Form>
                      <Form.Item>
                        <Radio.Group defaultValue={v.operResult}>
                          <Radio
                            disabled={v.operResult != "PASS"}
                            value={"PASS"}
                          >
                            同意
                          </Radio>
                          <Radio
                            disabled={v.operResult != "REFUSE"}
                            value={"REFUSE"}
                          >
                            不同意
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="备注">
                        <TooltipComponent
                          text={v.operDescription}
                          line={2}
                          width={400}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                );
              })}
            {procStatus == "AUDIT" && (
              <div>
                <div className="Divider"></div>
                <div className="modalTitle top20">
                  <span>
                    {this.isArray(detailData.logVOS) &&
                      detailData.logVOS.length == 1 &&
                      "二级审批"}

                    {detailData.logVOS &&
                      detailData.logVOS.length == 0 &&
                      "审批意见"}
                  </span>
                </div>
                <Form className="top20">
                  <Form.Item>
                    {getFieldDecorator("Approval", {
                      initialValue: "PASS",
                    })(
                      <Radio.Group>
                        <Radio value={"PASS"}>同意</Radio>
                        <Radio value={"REFUSE"}>不同意</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <TextFieldInput
                    form={form}
                    showLength={true}
                    label="备注"
                    placeholder="请输入"
                    allowClear
                    field="operDescription"
                    maxLength={500}
                    autoSize={{ minRows: 4, maxRows: 5 }}
                  />
                  {/* <Form.Item label="备注">
                                {getFieldDecorator('operDescription')(
                                    <TextArea maxLength={500} row={4}/>
                                )}
                            </Form.Item> */}
                </Form>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(ProjectTransfer);
