/**
 * Created by linxueqing on 2020/4/22
 */
import React from "react";
import { Form, Input } from "antd";
import "./index.less";
class BaseInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  // 正则校验
  validator = (rules, value, callback) => {
    const { validatorName, message } = this.props;
    if (value) {
      if (validatorName(value)) {
        callback();
      } else {
        callback(`请输入正确的格式：${message}`);
      }
    }

    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      label,
      required,
      message,
      initialValue,
      field,
      placeholder,
    } = this.props;
    const propsObj = {
      ...this.props,
    };

    Reflect.deleteProperty(propsObj, "validatorName");
    Reflect.deleteProperty(propsObj, "initialValue");

    return (
      <div className="baseInput">
        <Form.Item label={label}>
          {getFieldDecorator(field, {
            initialValue: initialValue || undefined,
            validate: [
              {
                trigger: "onBlur",
                rules: [
                  { required, message: `请输入${label || message}` },
                  { validator: this.validator },
                ],
              },
            ],
          })(<Input {...propsObj} placeholder={placeholder || "请输入"} />)}
        </Form.Item>
      </div>
    );
  }
}

export default BaseInput;
