/**
 * @description 组别select组件
 * @author zxz
 */
import React from "react";
import { Form, Select } from "antd";

const { Option } = Select;
class GroupSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      form,
      label,
      field,
      initialValue,
      message,
      dataSource,
      required,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form.Item label={label}>
        {getFieldDecorator(field, {
          initialValue: initialValue || undefined,
          rules: [
            {
              required,
              message: `请选择${label || message}`,
            },
          ],
        })(
          <Select
            placeholder="请选择"
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataSource &&
              dataSource.map((item) => {
                return <Option key={item.groupId}>{item.groupName}</Option>;
              })}
          </Select>
        )}
      </Form.Item>
    );
  }
}

export default GroupSelect;
