/*
 * @Description: 项目看板数据
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-15 10:07:35
 * @LastEditors: 陈金金
 * @LastEditTime: 2021-02-20 14:31:42
 */

import React from "react";
import TooltipComponent from "../../components/TooltipComponent"; // 文本溢出提示
import { isPositiveNum, isPositiveIncome, accMul } from "../../utils/common";
import { baseInput7 } from "../../utils/validate";
import { divide } from "lodash";
const checkNum = (num) => {
  let arrow = "";
  switch (isPositiveNum(num)) {
    case true:
      arrow = "tTOP";
      break;
    case false:
      arrow = "tBottom";
      break;
    default:
      arrow = "";
      break;
  }
  return arrow;
};
/**
 * @description: 查询条件
 * @author: 陈金金
 */
export const searchFormDataApply = (res) => [
  {
    label: "项目名称",
    name: "projectName",
    type: "input",
    validatorName: baseInput7,
    maxLength: 100,
  },
  //   {
  //     label: "工号",
  //     name: "staffCodeOrder",
  //     type: "input",
  //     validatorName: baseInput7,
  //     maxLength: 100,
  //   },
  //   {
  //     label: "姓名",
  //     name: "staffNameOrder",
  //     type: "input",
  //     validatorName: baseInput7,
  //     maxLength: 100,
  //   },
  {
    label: "员工",
    name: "staff",
    type: "input",
    validatorName: baseInput7,
    placeholder: "请输入姓名或工号",
    maxLength: 100,
  },
];

/**
 * @description: 项目详情查询条件
 * @author: 陈金金
 */
export const detailSearchFormDataApply = (res) => [
  {
    label: "人员搜索",
    name: "assetCode",
    type: "input",
    maxLength: 18,
  },
];

/**
 * @description: 表头信息
 * @author: 陈金金
 */
export const detailColumns = [
  {
    title: "序号",
    width: 100,
    render: (text, record, index) => `${index + 1}`,
  },
  {
    title: "工号",
    key: "staffCodeOrder",
    sorter: true,
    dataIndex: "staffCode",
    width: 130,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "姓名",
    dataIndex: "staffName",
    sorter: true,
    key: "staffNameOrder",
    width: 130,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "入职时间",
    dataIndex: "onBoardingTime",
    sorter: true,
    key: "onBoardingTimeOrder",
    width: 120,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "层级",
    dataIndex: "rank",
    sorter: true,
    key: "rankOrder",
    width: 110,
    render: (t) => (t ? <TooltipComponent width={100} text={t} /> : "-"),
  },
  {
    title: "采购价",
    dataIndex: "purchasePrice",
    sorter: true,
    key: "purchasePriceOrder",
    width: 110,
    render: (t) =>
      t ? <TooltipComponent width={100} text={isPositiveIncome(t)} /> : "-",
  },
  {
    title: "系统薪酬",
    sorter: true,
    dataIndex: "turnSalary",
    key: "turnSalaryOrder",
    width: 110,
    render: (t) =>
      t ? <TooltipComponent width={100} text={isPositiveIncome(t)} /> : "-",
  },
  // {
  //   title: "绩效基准值",
  //   sorter: true,
  //   dataIndex: "performanceStandard",
  //   key: "performanceStandardOrder",
  //   width: 130,
  //   render: (t) =>
  //     t ? <TooltipComponent width={120} text={isPositiveIncome(t)} /> : "-",
  // },
  {
    title: "非系统固定涨薪",
    sorter: true,
    dataIndex: "nonSystemFixedSalaryIncrease",
    key: "nonSystemFixedSalaryIncreaseOrder",
    width: 160,
    render: (t) =>
      t ? <TooltipComponent width={160} text={isPositiveIncome(t)} /> : "-",
  },
  {
    title: "个人绩效基准值",
    sorter: true,
    dataIndex: "personalPerformanceBenchmarks",
    key: "personalPerformanceBenchmarksOrder",
    width: 160,
    render: (t) =>
      t ? <TooltipComponent width={160} text={isPositiveIncome(t)} /> : "-",
  },
  {
    title: "项目绩效池基准值",
    sorter: true,
    dataIndex: "projectPerformancePoolBenchmarks",
    key: "projectPerformancePoolBenchmarksOrder",
    width: 180,
    render: (t) =>
      t ? <TooltipComponent width={180} text={isPositiveIncome(t)} /> : "-",
  },
  {
    title: "毛利",
    sorter: true,
    dataIndex: "staffGrossMargin",
    key: "staffGrossMarginOrder",
    width: 120,
    render: (t) =>
      t ? <TooltipComponent width={120} text={isPositiveIncome(t)} /> : "-",
  },
  {
    title: "毛利率",
    sorter: true,
    dataIndex: "staffGrossProfitMargin",
    key: "staffGrossProfitMarginOrder",
    width: 110,
    render: (t) =>
      t ? (
        <TooltipComponent width={100} text={t == 0 ? 0 : accMul(t, 100).toFixed(2) + "%"} />
      ) : (
        "-"
      ),
  },
];
/**
 * @description: 表头信息
 * @author: 陈金金
 */
export const columns = [
  {
    title: "序号",
    width: 60,
    fixed: "left",
    render: (text, record, index) => `${index + 1}`,
  },
  {
    title: "项目名称",
    key: "projectName",
    fixed: "left",
    dataIndex: "projectName",
    width: 260,
    // render: (t) => (t ? <TooltipComponent width={230} text={t} line/> : "-"),
  },
  {
    title: "组织",
    key: "organizationName",
    sorter: true,
    dataIndex: "organizationName",
    width: 180,
    render: (t) => (t ? <TooltipComponent width={180} text={t} /> : "-"),
  },
  {
    title: "项目类型",
    key: "projectTypeStr",
    dataIndex: "projectTypeStr",
    width: 100,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "业务类型",
    key: "businessTypeStr",
    dataIndex: "businessTypeStr",
    width: 130,
    render: (t) => (t ? <TooltipComponent width={120} text={t} /> : "-"),
  },
  {
    title: "项目收入",
    key: "projectIncome",
    sorter: true,
    dataIndex: "projectIncomeOrder",
    width: 280,
    render: (t, r) => {
      return (
        <div className="flexstart">
          <span className="tspan">
            <TooltipComponent
              maxWidth={200}
              text={r.projectIncome ? isPositiveIncome(r.projectIncome) : "-"}
            />
          </span>
          <span className={checkNum(r.projectIncomePer)}>
            <TooltipComponent
              maxWidth={80}
              text={
                r.projectIncomePer && r.projectIncomePer != 0
                  ? (Math.abs(r.projectIncomePer)*100).toFixed(2) + "%"
                  : "-"
              }
            />
            <i className="tLine"></i>
          </span>
        </div>
      );
    },
  },
  {
    title: "项目人数",
    sorter: true,
    key: "projectPeopleNum",
    dataIndex: "projectPeopleNumOrder",
    width: 110,
    render: (t, r) => {
      return (
        <div className="flexstart">
          <span className="tspan">
            <TooltipComponent
              maxWidth={40}
              text={r.projectPeopleNum ? r.projectPeopleNum : "-"}
            />
          </span>
          <span className={checkNum(r.projectPeoplePer)}>
            <TooltipComponent
              maxWidth={70}
              text={
                r.projectPeoplePer && r.projectPeoplePer != 0
                  ? (Math.abs(r.projectPeoplePer)*100).toFixed(2) + "%"
                  : "-"
              }
            />
            <i className="tLine"></i>
          </span>
        </div>
      );
    },
    // render:t=>t?<TooltipComponent width={110}  text={t}/>:'-',
  },
  {
    title: "人均成本",
    sorter: true,
    key: "perCapitaCost",
    dataIndex: "perCapitaCostOrder",
    width: 200,
    render: (t, r) => {
      return (
        <div className="flexstart">
          <span className="tspan">
            <TooltipComponent
              maxWidth={120}
              text={r.perCapitaCost ? isPositiveIncome(r.perCapitaCost) : "-"}
            />
          </span>
          <span className={checkNum(r.projectIncomePer)}>
            <TooltipComponent
              maxWidth={80}
              text={
                r.projectIncomePer && r.projectIncomePer != 0
                  ?(Math.abs(r.projectIncomePer)*100).toFixed(2) + "%"
                  : "-"
              }
            />
            <i className="tLine"></i>
          </span>
        </div>
      );
    },
    // render:t=>t?<TooltipComponent width={110}  text={'¥'+t}/>:'-',
  },
  {
    title: "项目毛利",
    sorter: true,
    key: "grossMargin",
    dataIndex: "grossProfitOrder",
    width: 210,
    render: (t, r) => {
      return (
        <div className="flexstart">
          <span className="tspan">
            <TooltipComponent
              maxWidth={130}
              text={r.grossProfit ? isPositiveIncome(r.grossProfit) : "-"}
            />
          </span>
          <span className={checkNum(r.grossProfitPer)}>
            <TooltipComponent
              maxWidth={80}
              text={
                r.grossProfitPer && r.grossProfitPer != 0
                  ? (Math.abs(r.grossProfitPer)*100).toFixed(2) + "%"
                  : "-"
              }
            />
            <i className="tLine"></i>
          </span>
        </div>
      );
    },
    // render:t=>t?<TooltipComponent width={130}  text={t}/>:'-',
  },
  {
    title: "毛利率",
    sorter: true,
    key: "grossProfitMargin",
    dataIndex: "grossProfitMarginOrder",
    width: 190,
    render: (t, r) => {
      return (
        <div className="flexstart">
          <span className="tspan">
            <TooltipComponent
              maxWidth={90}
              text={
                r.grossProfitMargin
                  ? r.grossProfitMargin == 0
                    ? 0 + "%"
                    : accMul(r.grossProfitMargin, 1) + "%"
                  : "-"
              }
            />
          </span>
          <span className={checkNum(r.grossProfitMarginPer)}>
            <TooltipComponent
              maxWidth={80}
              text={
                r.grossProfitMarginPer && r.grossProfitMarginPer != 0
                  ? (Math.abs(r.grossProfitMarginPer)*100).toFixed(2) + "%"
                  : "-"
              }
            />
            <i className="tLine"></i>
          </span>
        </div>
      );
    },
    // render:t=>t?<TooltipComponent width={130}  text={t}/>:'-',
  },
];
/**
 * @description: 项目详情信息
 * @param {type}
 * @return:
 * @author: 陈金金
 */
export const detailKey = [
  {
    key: "projectName",
    name: "项目名称：",
    before: "",
    after: "",
  },
  {
    key: "projectTypeStr",
    name: "项目类型：",
    before: "",
    after: "",
  },
  {
    key: "businessTypeStr",
    name: "业务类型：",
    before: "",
    after: "",
  },
  {
    key: "projectIncome",
    name: "项目收入：",
    before: "¥",
    after: "",
  },
  {
    key: "projectPeopleNum",
    name: "项目人数：",
    before: "",
    after: "",
  },
  {
    key: "perCapitaCost",
    name: "人均成本：",
    before: "¥",
    after: "",
  },
  {
    key: "grossProfit",
    name: "项目毛利：",
    before: "¥",
    after: "",
  },
  {
    key: "grossProfitMargin",
    name: "项目毛利率：",
    after: "%",
    before: "",
  },
  {
    key: "approvalIncome",
    name: "当月核准后项目收入：",
    before: "¥",
    after: "",
  },
  {
    key: "approvalCost",
    name: "当月核准后项目成本：",
    before: "¥",
    after: "",
  },
];
