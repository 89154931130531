/*
 * @文档描述: 部门选择框
 * @author: 西园
 * @Date: 2020-06-07 15:48:02
 * @LastEditTime: 2020-08-11 10:44:07
 * @LastEditors: 陈金金
 */
import React, { Fragment } from 'react';
import { Form, TreeSelect } from 'antd';
const { TreeNode } = TreeSelect;

class DepartmentSelect extends React.Component {
    state = {
        departmentData: []
    };
    componentDidMount() {
        this.queryDepartmentData();
    }
    /**
     * @函数描述: 查询部门数据
     * @return:
     */
    queryDepartmentData = () => {
        this.$http.queryOrgTree().then(res => {
            if (res && res.errorCode == 200) {
                this.setState({
                    departmentData: res.data || []
                });
            } else {
                this.setState({
                    departmentData: []
                });
            }
        });
    };
    handleChange = val => {
        const { form, field, labelInValue, relationName } = this.props;
        const valueJson = form.getFieldValue(field);
        if (valueJson) {
            const nowKey = labelInValue ? (val ? val.value : undefined) : val;
            const preKey = labelInValue ? valueJson.value : valueJson;
            if (nowKey !== preKey) {
                form.setFields({
                    [`${relationName}`]: {
                        value: undefined
                    }
                });
            }
        }
        return val;
    };
    /**
     * @函数描述: 渲染部门treeSelect
     * @param {type}
     * @return:
     */
    renderTreeNodes = data =>
      
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode
                        title={item.organizationName}
                        key={item.organizationCode}
                        dataRef={item}
                        value={item.organizationCode}
                        disabled={true}
                    >
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode
                    key={this.props.belongRelation?item.organizationCode+'-'+item.belongRelation:item.organizationCode}
                    {...item}
                    title={item.organizationName}
                    value={this.props.belongRelation?item.organizationCode+'-'+item.belongRelation:item.organizationCode}
                    disabled={item.organizationLevel * 1 < 3}
                />
            );
        });
    
      
    initialData = () => {
        let initialData;
        const { initialValue, labelInValue } = this.props;
        if (initialValue) {
            if (labelInValue) {
                initialData = initialValue.label ? initialValue : undefined;
            } else {
                initialData = initialValue;
            }
        }
        return initialData;
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { label, required, message, field, labelInValue, placeholder } = this.props;
        const { departmentData } = this.state;
        return (
            <Fragment>
                <Form.Item label={label}>
                    {getFieldDecorator(field, {
                        initialValue: this.initialData(),
                        getValueFormEvent: this.handleChange,
                        validate: [
                            {
                                trigger: 'onChange',
                                rules: [{ required, message: `请选择${label || message}` }]
                            }
                        ]
                    })(
                        <TreeSelect
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={placeholder || '请选择'}
                            treeDefaultExpandAll
                            labelInValue={labelInValue}
                            onChange={this.handleChange}
                            allowClear
                        >
                            {this.renderTreeNodes(departmentData)}
                        </TreeSelect>
                    )}
                </Form.Item>
            </Fragment>
        );
    }
}

export default DepartmentSelect;
