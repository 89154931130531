/*
 * @Description: 弹窗+表单组件
 * @Author: 金金
 * @Date: 2020-06-01 10:14:59
 * @LastEditTime: 2020-08-07 17:12:29
 * @LastEditors: 文倩
 * 
 * 数据格式 FormData=[{
    name: 'organizationCode',                                // 字段名称
    label: '部门',
    item: () =><Select                                       // 表单控件
    allowClear
    placeholder="请选择"
   >
  <Option key={v.rfidCode}>{v.rfidName}</Option>
 </Select>// 校验规则,                             
    rules: [{ required: true,message:'请输入部门' }]           // 校验规则
},{
    dicGroup:'RENT_TYPE',                                     //  需要查询数据字典可以直接传入dicGroup，并注明类型：type:'select',
    label: '租赁方式',
    type:'select',
    field: 'rentType ',
    required:true,
}, type:'input',
    field: 'computerType ',
    maxLength:50,
    validatorName:baseInput7,                                 //引入input表单验证
    label: '机型配置',
    required:true,
]
 */
import React from "react";
import "./component.less";
import { Modal, Form, Button, Spin, Row, Col, Select } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import BaseInput from "../components/baseInput";
import BaseInput2 from "../components/baseInput2";
import StatusSelect from "../components/statusSelect"; //导入枚举类型下拉框
import DepartmentSelect from "../components/departmentSelect"; // 部门选择框
import GroupSelect from "../components/GroupSelect"; //组别select组件
const { Option } = Select;
class AlertModal extends React.Component {
  state = {
    btnLoading: false, //确定按钮加载动画
    rfidCode: "",
    curNum: 0,
  };
  componentDidMount() {
    console.log(this.props.modalData);
    // this.props.form.setFieldsValue(this.props.modalData);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, v) => {
      if (!err) {
        this.setState({ btnLoading: true });
        this.props.onOk(v, () => {
          this.setState({ btnLoading: false });
        });
      }
    });
  };
  getRfidCode = (rfidCode) => {
    this.setState({ rfidCode });
    this.props.getRfidCode(rfidCode, (res) => {
      this.setState({ rfidCode: "" });
      this.props.form.setFieldsValue({
        [rfidCode]: res,
      });
    });
  };

  /**
   * @method 更新BaseInput输入字数
   */
  setCurNum = (value) => {
    console.log("value -->", value);
    this.setState({ curNum: value.length });
  };

  /**
   * @description:
   * @param {boolean}  isShow 控件显示隐藏属性
   * @return:
   */
  switchItem = (v) => {
    const { form } = this.props;
    const { rfidCode, curNum } = this.state;
    const { maxLength } = v;
    const { getFieldDecorator } = this.props.form;
    let Item = null;

    if (v.isShow == false) return false;
    if (v.FormItem) {
      Item = v.item(form);
    } else {
      switch (v.type) {
        case "input":
          Item = (
            <BaseInput2 form={form} {...v} />
            // <BaseInput
            //   form={form}
            //   {...v}
            //   addonAfter={
            //     <span>
            //       <span
            //         style={{
            //           color:
            //             curNum > maxLength ? "#f5222d" : "rgba(0, 0, 0, 0.65)",
            //         }}
            //       >
            //         {curNum}
            //       </span>
            //       {` / ${maxLength}`}
            //     </span>
            //   }
            //   onChange={(e) => this.setCurNum(e.target.value)}
            // />
          );

          break;
        case "select":
          Item = <GroupSelect {...v} form={form} />;
          break;
        case "statusSelect": //     数据字典查询
          Item = <StatusSelect form={form} {...v} />;
          break;
        case "DepartmentSelect": //   部门
          Item = (
            <DepartmentSelect
              labelInValue={v.labelInValue}
              form={form}
              label={v.label}
              field={v.field}
              initialValue={v.initialValue}
              required={true}
              relationName={v.relationName}
              {...v}
            />
          );
          break;
        default:
          Item = (
            <Form.Item label={v.label}>
              {getFieldDecorator(v.name, {
                initialValue: v.initialValue || undefined,
                rules: v.rules,
              })(v.item())}
            </Form.Item>
          );
          break;
      }
    }
    return Item;
  };
  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };
  render() {
    /**
     * @description:
     * @param {Boolean} Loading // 弹窗加载动画
     * @param {Boolean} visible // 弹窗显示隐藏
     * @return:
     */
    const { Loading, visible, FormData, isRfid, ModalLoading } = this.props;
    const { btnLoading } = this.state;
    const { props } = this;
    return (
      <>
        <Modal
          {...props}
          centered
          loading={ModalLoading ? ModalLoading : null}
          maskClosable={false}
          // onCancel={()=>this.onCancel()}
          visible={visible}
          footer={[
            <div>
              <Button key="cancle" onClick={() => this.onCancel()}>
                取消
              </Button>
              <Button
                key="sure"
                onClick={this.handleSubmit}
                loading={btnLoading}
                type="primary"
              >
                提交{" "}
              </Button>
            </div>,
          ]}
        >
          <Spin spinning={Loading ? Loading : null}>
            <div>
              {props.text && (
                <p className="modalText">
                  <InfoCircleFilled />
                  {props.text}
                </p>
              )}
              <Form
                // roleNames
                onSubmit={(v) => this.handleSubmit(v)}
              >
                {FormData.map((v, i) => {
                  return <div key={i}>{this.switchItem(v)}</div>;
                })}
              </Form>
            </div>
          </Spin>
        </Modal>
      </>
    );
  }
}

export default Form.create()(AlertModal);
