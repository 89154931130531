/*
 * @文档描述: table组件改造，勿动。。。之前的有备份。。。有问题私聊我
 * @author: 文倩
 * @Date: 2020-06-01 10:25:07
 * @LastEditTime: 2020-08-14 18:49:30
 * @LastEditors: 文倩
 */

import React, { Component } from "react";
import { Table, Pagination } from "antd";
import NoData from "../../components/noData";
import "../component.less";
import { Fragment } from "react";

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      this.props.rowSelection &&
      this.props.rowSelection.selectedRowKeys != nextProps.rowSelection &&
      nextProps.rowSelection.selectedRowKeys
    ) {
      this.setState({
        selectedRowKeys: nextProps.rowSelection.selectedRowKeys,
      });
    }
  }

  onChange = (page, size) => {
    this.props.handleChangePage(page, size);
  };
  onShowSizeChange = (page, size) => {
    this.props.handleChangePageSize(page, size);
  };
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    this.props.rowSelection.onChange(selectedRowKeys);
  };
  render() {
    const {
      dataSource,
      total,
      rowSelection,
      keyName,
      current,
      pageSize,
      isPagination,
    } = this.props;
    const { selectedRowKeys } = this.state;

    return (
      <Fragment>
        <div className="table">
          <Table
            rowKey={(r) => r[keyName ? keyName : "id"]}
            scroll={{ x: "100%" }}
            {...this.props}
            rowSelection={
              rowSelection
                ? {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                  }
                : null
            }
            locale={{
              emptyText: (
                <div className="noData" style={{ width: "100%" }}>
                  <NoData width={200} />
                </div>
              ),
            }}
            pagination={false}
            showHeader={dataSource.length > 0}
          />
          {isPagination && dataSource.length > 0 && (
            <div className="paginationBox">
              <Pagination
                current={current}
                total={total}
                pageSize={pageSize}
                showQuickJumper={true}
                showSizeChanger={true}
                pageSizeOptions={["10", "20", "50"]}
                onChange={this.onChange}
                onShowSizeChange={this.onShowSizeChange}
              />
              {total > 0 && (
                <span>
                  共<font>{total}</font>条记录
                </span>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
export default TableComponent;
