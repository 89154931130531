/**
 * Created by linxueqing on 2020/4/22.
 */
import React from "react";
import { Form, InputNumber } from "antd";

class BaseInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  // 正则校验
  validator = (rules, value, callback) => {
    const { validatorName, message } = this.props;
    if (value) {
      if (validatorName(value)) {
        callback();
      } else {
        callback(`请填写正确格式：${message}`);
      }
    } else {
      callback();
    }
  };
  // 指定输入框展示值的格式
  limitDecimals = (value) => {
    const reg = /^(\-)*(\d+)\.(\d\d).*$/;
    if (typeof value === "string") {
      return !isNaN(Number(value)) ? value.replace(reg, "$1$2.$3") : "";
    } else if (typeof value === "number") {
      return !isNaN(value) ? String(value).replace(reg, "$1$2.$3") : "";
    } else {
      return "";
    }
  };

  /**
   *  数字输入框组件
   * @props的属性
   * label 标题
   * placeholder 提示
   * required 是否必填
   * field 字段名
   * step  最小值以及每次改变步数
   * minLength  输入框内容最大长度
   * maxLength  输入框内容最大长度
   * form  当前表单 通常值 form={this.props.form}
   * step 每次改变步数，可以为小数（数字输入框）
   * max  输入数字最大值
   * min  输入数字最小值
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const { label, required, field, message, initialValue } = this.props;
    const propsObj = {
      ...this.props,
    };
    delete propsObj["validatorName"];
    return (
      <div className="baseInput">
        <Form.Item label={label}>
          {getFieldDecorator(field, {
            initialValue: initialValue || undefined,
            validate: [
              {
                trigger: "onBlur",
                rules: [
                  { validator: this.validator },
                  { required, message: `请输入${label ? label : message}` },
                ],
              },
            ],
          })(<InputNumber {...propsObj} />)}
        </Form.Item>
      </div>
    );
  }
}

export default Form.create()(BaseInput);
