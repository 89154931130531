/*
 * @Description: 搜索框组件
 * @Author: 西园
 * @Date: 2020-06-01 11:00:42
 * @LastEditTime: 2020-08-26 10:25:50
 * @LastEditors: 文倩
 * 表单查询模块 目前支持 输入 下拉选择 时间区域选择
 * (时间选择需通过format方法自行处理)
 * 数据格式：FormData: [{
                label: '盘点日期',    
                name: 'pandianDate',
                type: 'rangeTime'       // 时间区域选择
            }, {
                label: '状态',
                name: 'freeStockStatus',  // 状态下拉
                type: 'statusSelect',
                placeholder: '请选择',
                dicGroup: "FREE_STOCK_STATUS"  // 类型参考最下边
            },{
                label: '盘点结果',
                name: 'pandianRes',
                type: 'select',          // 下拉选择
                placeholder: '请选择',
                mode: multiple | tags,   // 设置 Select 的模式为多选或标签 ,不配置为正常状态
                selectData: [{
                    name: '盘亏',
                    value: 1
                },{
                    name: '盘盈',
                    value: 2
                }]
            },{
                label: '盘点仓库',
                name: 'pandianCk',
                type: 'select',
                placeholder: '请选择',
                selectData: [{
                    name: '仓库1',
                    value: 1
                },{
                    name: '仓库2',
                    value: 2
                }]
            }]
 */
import React from "react";
import _ from "lodash";
import "./index.less";

import {
  Button,
  Form,
  Select,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Col,
  message,
  TreeSelect,
} from "antd";
import { SearchOutlined as SearchIcon } from "@ant-design/icons";
import { isArray } from "../../utils/common";
import BaseInput from "../baseInput/index";
import NumberInput from "../baseInput/number";
import moment from "moment";
import uniqueId from "lodash";
import DepartmentSelect from "../departmentSelect/index"; // 部门选择框

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const { TreeNode, SHOW_ALL } = TreeSelect;

const formItemLayout_3 = {
  labelCol: { span: 3 },
  // labelAlign: "left",
  wrapperCol: { span: 18 },
};
const formItemLayout_2 = {
  labelCol: { span: 2 },
  // labelAlign: "left",
  wrapperCol: { span: 18 },
};

class SearchForm extends React.Component {
  state = {
    statusList: {}, // 状态数据
    isopen: false,
    currentTab:
      this.props.tabList && _.head(this.props.tabList).key, //当前选中tab
    rangeTimeDisabled: false, //时间筛选置灰
  };
  componentDidMount() {
    this.renderStart(this.props);
  }

  renderStart = (props) => {
    const { FormData, dicGroup } = props;
    let statusArr = [];

    if (dicGroup) {
      statusArr.push(dicGroup);
    }

    FormData &&
      FormData.forEach((item) => {
        if (item.type === "statusSelect" && item.dicGroup) {
          statusArr.push(item.dicGroup);
        }
      });

    if (statusArr.length > 0) {
      this.queryDropStatus(statusArr);
    }
  };

  /**
   * @函数描述: 查询状态下拉
   * @param {String} RETURN_WAYS 归还方式,
   * @param {String} RETURN_TYPE 归还类型,
   * @param {String} RETURN_STATUS 归还状态,
   * @param {String} INVENTORY_RESULT 盘点结果,
   * @param {String} ASSETS_STATUS 资产状态,
   * @param {String} FREE_STOCK_STATUS 易耗品状态,
   * @param {String} PROC_STATUS 流程状态,
   * @param {String} PURCHASE_APPLY_SUM 低值易耗品金额限制,
   * @return:
   */
  queryDropStatus = (statusArr) => {
    this.$http.queryDicCacheList(statusArr).then((res) => {
      if (res && res.errorCode == 200) {
        this.setState({
          statusList: res.data || {},
        });
      } else {
        this.setState({
          statusList: {},
        });
      }
    });
  };

  /**
   * @description: 设置tabs
   * @param {Array} tabList 渲染tabs   [{value:"固定成本",key:"0"}]
   * @return:
   */
  renderTabsHtml = () => {
    const { tabList } = this.props;
    const { currentTab } = this.state;

    let TabsHTML = null;
    if (tabList && tabList.length > 0) {
      TabsHTML = (
        <ul className="tabList">
          {tabList.map((item) => {
            return (
              <li
                key={item.key || item.value}
                onClick={() =>
                  tabList.length > 1
                    ? this.changeTabs(item.key)
                    : () => {}
                }
              >
                <font
                  className={
                    currentTab == item.key ? "active" : ""
                  }
                >
                  {item.value}
                </font>
                {currentTab == item.key &&
                  tabList.length > 1 && <span />}
              </li>
            );
          })}
        </ul>
      );
    }
    return TabsHTML;
  };

  componentWillReceiveProps(nextProps) {
    let flag = false;
    if (
      this.props.FormData !== nextProps.FormData &&
      !flag
    ) {
      this.renderStart(nextProps);
      flag = true;
    }
  }

  /**
   * @description: tabs切换回调
   * @param {key}  tabs key
   * @return:
   */
  changeTabs = (key) => {
    this.setState(
      {
        currentTab: key,
      },
      () => {
        this.props.hansleChangeTabs(key);
      }
    );

    // this.props.form.resetFields(); // 重置表单
  };
  onFinish = () => {
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (err) {
          return false;
        }
        const data = {};
        // let flag = false;

        // 去除含有undefined的item
        Object.keys(values).forEach((res) => {
          if (
            (values[res] && !Array.isArray(values[res])) ||
            (Array.isArray(values[res]) &&
              values[res].length > 0)
          ) {
            // flag = true;
            data[res] = values[res];
          }
        });

        // if (!flag) {
        //     message.destroy();
        //     message.warning('搜索条件不能为空！');
        //     return;
        // }
        this.props.handleSubmit(data);
      }
    );
  };

  /**
   * @description: 表单重置
   * @return: 重制完成后调用父组件查询列表第一页数据
   */
  onReset = () => {
    this.props.form.resetFields(); // 重置表单
    this.props.handleSubmit();
    this.setState({
      rangeTimeDisabled: false,
    });
  };

  /**
   * @description: 设置表单
   * @param {Array}  FormData 父组件传递进来的表单配置项
   * @return:
   */
  setFormHtml = () => {
    const { getFieldDecorator } = this.props.form;
    const { FormData } = this.props;
    return (FormData || []).map((item) => {
      return (
        <Col span={8} key={item.name}>
          {this.renderFormItem(item)}
        </Col>
      );
    });
  };

  /**
   * @description: 修改统计字段时的回调，清空时间筛选值，并不允许筛选
   * @param {type}
   * @return {type}
   * @author: 文倩
   */
  handleChanage = (e, FormItem) => {
    if (
      e == "null" &&
      FormItem.name == "timeType" &&
      FormItem.isEntry
    ) {
      this.props.form.resetFields("month");
      this.setState({
        rangeTimeDisabled: true,
      });
    } else {
      this.setState({
        rangeTimeDisabled: false,
      });
    }
  };

  /**
   * @description: treeSelect数据处理
   * @param {type}
   * @return {type}
   * @author: 赵娟
   */
  treeData = (selectData, idOrName) => {
    let perData =
      selectData.length != 0 &&
      selectData.map((item) => {
        return (item = {
          title: item.organizationName,
          value: idOrName
            ? item.organizationName
            : item.organizationCode,
          children:
            item.children && item.children.length != 0
              ? this.treeData(item.children || [], idOrName)
              : null,
        });
      });
    return perData || [];
  };

  /**
   * @description: 根据配置项类型设置单个表单项
   * @param {Object}  FormItem 父组件传递进来的表单配置项
   * @param {String}  FormItem.type input: <Input />
   *                                select: <Select />
   *                                rangeTime: 选择时间段
   *                                departmentSelect： 部门筛选
   * @return:
   */
  renderFormItem = (FormItem) => {
    let html = null;

    const { getFieldDecorator } = this.props.form;

    const { statusList, rangeTimeDisabled } = this.state;
    switch (FormItem.type) {
      case "input": // 输入框
        html = (
          <BaseInput
            form={this.props.form}
            label={FormItem.label}
            field={FormItem.name}
            maxLength={FormItem.maxLength || 100}
            placeholder={FormItem.placeholder || "请输入"}
            validatorName={FormItem.validatorName || ""}
            message={FormItem.message || "请输入"}
            allowClear
          />
        );
        break;
      case "inputNumber": // 数字输入框
        html = (
          <NumberInput
            form={this.props.form}
            label={FormItem.label}
            field={FormItem.name}
            min={0}
            max={FormItem.maxLength || 100}
            placeholder={FormItem.placeholder || "请输入"}
            validatorName={FormItem.validatorName || ""}
            message={FormItem.message || "请输入"}
            allowClear
          />
        );
        break;
      case "statusSelect": // 状态下拉框
        html = (
          <Form.Item label={FormItem.label}>
            {getFieldDecorator(FormItem.name)(
              <Select
                placeholder={
                  FormItem.placeholder || "请选择"
                }
                mode={FormItem.mode || ""}
                allowClear
              >
                {(statusList[FormItem.dicGroup] || []).map(
                  (res) => {
                    return (
                      <Option key={res.key}>
                        {res.value || ""}
                      </Option>
                    );
                  }
                )}
              </Select>
            )}
          </Form.Item>
        );
        break;
      case "select": // 选择框
        html = (
          <Form.Item label={FormItem.label}>
            {this.props.form.getFieldDecorator(
              FormItem.name,
              {
                initialValue:
                  FormItem.initialValue || undefined,
              }
            )(
              <Select
                placeholder={
                  FormItem.placeholder || "请选择"
                }
                mode={FormItem.mode || ""}
                allowClear
                // onChange={(e)=>this.handleChanage(e,FormItem) || null}
              >
                {FormItem.selectData.map((res) => {
                  return (
                    <Option
                      key={
                        res[
                          FormItem.mapping
                            ? FormItem.mapping.value
                            : "value"
                        ]
                      }
                    >
                      {
                        res[
                          FormItem.mapping
                            ? FormItem.mapping.name
                            : "name"
                        ]
                      }
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        );
        break;
      case "treeSelect": // 分组选择框
        html = (
          <Form.Item label={FormItem.label}>
            {getFieldDecorator(FormItem.name, {
              initialValue:
                FormItem.initialValue || undefined,
            })(
              <TreeSelect
                multiple
                style={{ width: "100%" }}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                treeData={this.treeData(
                  FormItem.selectData || [],
                  FormItem.idOrName
                )}
                placeholder={
                  FormItem.placeholder || "请选择"
                }
                treeDefaultExpandAll
                showArrow
                showSearch={false}
                allowClear
                onFocus={() => {}}
              />
            )}
          </Form.Item>
        );
        break;
      case "rangeTime": // 选择时间范围
        html = (
          <Form.Item label={FormItem.label}>
            {getFieldDecorator(FormItem.name)(
              <RangePicker
                ref={(picker) => (this.picker = picker)}
                open={this.state.isopen}
                style={{ width: "100%" }}
                // disabled={rangeTimeDisabled}
                onOpenChange={(status) => {
                  // console.log(status)
                  if (status) {
                    this.setState({ isopen: true });
                  } else {
                    this.setState({ isopen: false });
                  }
                }}
                onPanelChange={(value) => {
                  this.props.form.setFieldsValue({
                    [FormItem.name]: value,
                  });
                  this.setState({
                    isopen: false,
                  });
                }}
                placeholder={
                  FormItem.placeholder || [
                    "开始日期",
                    "结束日期",
                  ]
                }
                // mode={FormItem.mode || ''}
                format={FormItem.format || ""}
              />
            )}
          </Form.Item>
        );
        break;
      case "DatePicker": // 选择日期
        html = (
          <Form.Item label={FormItem.label}>
            {getFieldDecorator(FormItem.name, {
              initialValue:
                FormItem.initialValue || undefined,
            })(<DatePicker style={{ width: "100%" }} />)}
          </Form.Item>
        );
        break;
      case "MonthPicker": // 选择日期
        html = (
          <Form.Item label={FormItem.label}>
            {getFieldDecorator(FormItem.name, {
              initialValue:
                FormItem.initialValue || undefined,
            })(
              <MonthPicker
                style={{ width: "100%" }}
                disabledDate={(e) => e && e > moment()}
              />
            )}
          </Form.Item>
        );
        break;
      default:
        html = null;
        break;
    }
    return html;
  };

  offsetFn = (FormData) => {
    let offset = 0;
    let num = FormData.length % 3;
    if (
      FormData.length > 3 &&
      num > 0 &&
      FormData[0].label.length !==
        FormData[num].label.length
    ) {
      offset = 1;
    }
    return offset;
  };
  render() {
    const { FormData, form } = this.props;
    let length = null;
    if (isArray(FormData)) {
      length = FormData.length;
    }
    return (
      <div className="search-form-card">
        {this.renderTabsHtml()}
        {FormData && (
          <Form
            labelCol={{
              span:
                FormData[0] &&
                FormData[0].label &&
                FormData[0].label.length === 4
                  ? 3
                  : 2,
            }}
            wrapperCol={{ span: 16 }}
            form={form}
          >
            <Row gutter={16} justify="start">
              {(FormData || []).map((item) => {
                return (
                  <Col span={8} key={item.name}>
                    {this.renderFormItem(item)}
                  </Col>
                );
              })}
              <Col
                span={
                  length != 3 ? 24 - (length % 3) * 8 : 24
                }
                style={{ textAlign: "left" }}
              >
                <Form.Item
                  {...{
                    wrapperCol: {
                      offset: this.offsetFn(FormData),
                      span: 19,
                    },
                  }}
                >
                  <div className="Space8">
                    <Button
                      type="primary"
                      onClick={this.onFinish}
                    >
                      搜索
                    </Button>
                    <Button
                      htmlType="button"
                      onClick={this.onReset}
                    >
                      重置
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}
export default Form.create()(SearchForm);
