/*
 * @Description: 结项申请
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 16:21:55
 */


import React, { Component } from 'react';
import { Button, Row, Col, Form, Modal, Radio, message } from 'antd';
import './Components.less'
import Table from '../../../components/table/index';
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示
import TextFieldInput from '../../../components/sceneInput/TextAreaInput';
class EndProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 3,
            visible: false,
            loading: false,
            pageNo: 1,
            btnLoading: false,
            pageSize: 10,
            isCard: false,
            searchValue: {},
            detailData: {},
            selectedRowKeys: [],
            columns: [{
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '工号',
                dataIndex: 'staffCode',
                key: 'staffCode',
                render: t => t ? <TooltipComponent width={120} text={t} /> : '-'
            },
            {
                title: '姓名',
                key: 'staffName',
                dataIndex: 'staffName',
                render: t => t ? <TooltipComponent width={170} text={t} /> : '-',
            }, {
                title: '入职时间',
                key: 'onBoardingTime',
                dataIndex: 'onBoardingTime',
                render: t => t ? <TooltipComponent width={120} text={t} /> : '-',
            }, {
                title: '离职时间',
                key: 'resigningTime',
                dataIndex: 'resigningTime',
                render: t => t ? <TooltipComponent width={120} text={t} /> : '-',
            }]
        }
    }
    componentDidMount() {
        this.getDetail(this.props.businessKey)
    }
    /**
     * @description: 结项申请详情数据获取
     * @param {type} 
     * @return: 
     * @author: 陈金金
     */
    getDetail = (postProjectId) => {
        this.setState({ Loading: true })
        this.$http.getPostProjectById({ postProjectId }).then(res => {
            this.setState({ Loading: false })
            if (res && res.errorCode == 200) {
                this.setState({
                    detailData: res.data,
                })
            }
        })
    }
    /**
    * @description:  结项申请流程审批
    * @param {type} Approval 1，通过，2 拒绝
    * @author: 陈金金
    */
    handleAddSure = () => {
        const { businessKey } = this.props;
        const { detailData } = this.state;
        this.props.form.validateFieldsAndScroll((err, v) => {
            this.setState({ btnLoading: true })
            this.$http[`${v.Approval == 'PASS' ? "postProjectPass" : "postProjectRefuse"}`]
                ({
                    postProjectId: businessKey,
                    taskId: detailData.procStatusVO.taskId,
                    operDescription: v.operDescription
                }).then(res => {
                    this.setState({ btnLoading: false })
                    if (res && res.errorCode == 200) {
                        message.destroy();
                        message.success(res.message);
                        this.props.closeModal(true)
                    }
                })

        });
    }

    handleReturnStockBtnCancle = () => {
        this.props.form.resetFields();
        this.setState({ visible: false })
        this.props.closeModal()
    }
    /**
* @description: 自动回显数据
* @return:
*/
    renderDetailKeyHTML = () => {
        const { detailData } = this.state;
        return [
            { name: '项目名称：', key: 'projectName', span: 8 },
            { name: '项目类型：', key: 'projectTypeStr', span: 8 },
            { name: '业务类型：', key: 'businessTypeStr', span: 8 },
            { name: '结项时间：', key: 'postProjectTime', span: 12 }
        ].map(item => {
            return (
                <Col span={item.span} key={item.key}>
                    <div className="detailbox">
                        <span className="label" style={{ display: item.block ? 'block' : 'inline-block' }}>{item.name}</span>
                        <div className="value" style={{ marginTop: item.block ? '10px' : '' }}>
                            {
                                detailData[item.key] ?
                                    <TooltipComponent
                                        text={detailData[item.key]}
                                        line={item.key == 'remarks' ? 2 : undefined}
                                        width={item.key == 'remarks'?600:120}
                                    /> : '--'
                            }
                        </div>
                    </div>
                </Col>
            );
        });
    };
    render() {
        const { visible, procStatus, form } = this.props;
        const { getFieldDecorator } = this.props.form
        const { detailData, btnLoading } = this.state;
        return (
            <>
                {
                    visible &&
                    <Modal
                        className="SalaryChangeModal modalInfo"
                        title="结项申请"
                        visible={visible}
                        footer={procStatus != 'AUDIT' ? false : [
                            <div key="EndProject">
                                <Button key="cancle" onClick={() => this.handleReturnStockBtnCancle()}>取消</Button>
                                <Button key="sure" onClick={this.handleAddSure} loading={btnLoading} type="primary" >提交 </Button>
                            </div>
                        ]}
                        width={745}
                        onCancel={this.handleReturnStockBtnCancle}
                        maskClosable={false}
                    >
                        <div className='modalTitle'><span>申请详情</span></div>
                        <Row className='bottom50'>
                            {this.renderDetailKeyHTML()}
                            <Table
                                rowKey={r => r.assetCode}
                                columns={[...this.state.columns]}
                                dataSource={detailData && detailData.staffResigningVOS || []}
                                scroll={{ x: "100%" }}

                                isPagination={false}
                            />
                            <Col span={24} >
                                <div className="detailbox">
                                    <span className="label" style={{ display: "block" }}>项目结项说明</span>
                                    <div className="value" style={{ marginTop: '10px' }}>
                                        <TooltipComponent
                                                text={detailData.remarks}
                                                line={2}
                                                width={680}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {this.isArray(detailData.logVOS) && detailData.logVOS.map((v, i) => {
                            return <div className="logVOSList top50" key={v.taskDefKey}>
                                <div className="Divider"></div>
                                <div className='modalTitle top20' >
                                    <span>审批意见</span>
                                </div>
                                <Form className="top20">
                                    <Form.Item >
                                        <Radio.Group defaultValue={v.operResult}>
                                            <Radio disabled={v.operResult != 'PASS'} value={'PASS'}>同意</Radio>
                                            <Radio disabled={v.operResult != 'REFUSE'} value={'REFUSE'}>不同意</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="备注">
                                        <TooltipComponent
                                            text={v.operDescription}
                                            line={2}
                                            width={680}
                                        />
                                    </Form.Item>

                                </Form>
                            </div>
                        })
                        }

                        {
                            procStatus == 'AUDIT' && <div>
                                <div className="Divider"></div>
                                <div className='modalTitle top20'><span>审批意见</span></div>
                                <Form className="top20">
                                    <Form.Item >
                                        {getFieldDecorator('Approval', {
                                            initialValue: 'PASS',
                                        })(
                                            <Radio.Group>
                                                <Radio value={'PASS'}>同意</Radio>
                                                <Radio value={'REFUSE'}>不同意</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    <TextFieldInput
                                        form={form}
                                        showLength={true}
                                        label="备注"
                                        placeholder="请输入"
                                        allowClear
                                        field="operDescription"
                                        maxLength={500}
                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                    />
                                    {/* <Form.Item label="备注">
                                    {getFieldDecorator('operDescription')(
                                        <TextArea maxLength={500} row={4} placeholder="请输入" />
                                    )}
                                </Form.Item> */}

                                </Form>
                            </div>}

                    </Modal>
                }
            </>
        );
    }
}

export default Form.create()(EndProject);