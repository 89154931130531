import React from 'react';
import noPermission2X from '../../assets/image/noPermission2x.png';
import HeaderComponent from "../../components/header/header";
import './index.less'
function NoPermission(props){
    return (
       <div>
           <HeaderComponent {...props}/>
            <div className="Page403" style={{height:"calc(100vh-100px)"}}>
         <div className="imgbox">
              <img src={noPermission2X} alt=""/>
        </div>
    </div>
       </div>
    )
}
export default NoPermission