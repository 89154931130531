import React from "react";
import { Route } from "react-router-dom";
import _ from "lodash";
import history from "../api/history";
import { isArray } from "../utils/common";
import utils from "../utils/utils";
import Page403 from "../pages/StaticPages/Page403";

class AuthorizedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: false,
      authbtn: [],
      isHttp: false,
      parentTitleList: [],
      childTitleList: [],
    };
  }

  componentWillMount() {
    // if(utils.get('userInfo')){
    //     if (utils.get('menuList')) {
    //         this.ifArrVal(utils.get('menuList'))
    //     } else {
    //         if (utils.get('userInfo')) {
    //             history.push('/Page403')
    //         } else {
    //             history.push('/')
    //         }
    //     }
    // }else{
    //     history.push('/')
    //     utils.clear();
    // }
  }

  // 判断是否有访问权限
  ifArrVal = (data) => {
    if (!data.length) return false;
    let flag = false;
    isArray(data) &&
      data.map((v) => {
        const bigNameList = this.props.name && this.props.name.split("-");
        if (this.props.path === v.key) {
          flag = true;
          this.setState({ isMenu: true, authbtn: v.buttonAuthority });
        } else {
          if (
            this.props.path != v.key &&
            !flag &&
            _.includes(bigNameList, v.permissionName)
          ) {
            if (!v.siderMenu && v.buttonAuthority) {
              this.setState({ authbtn: v.buttonAuthority });
            }
          }
          if (isArray(v.siderMenu)) {
            this.ifArrVal(v.siderMenu);
          }
        }

        return v;
      });
  };

  render() {
    const {
      component: Component,
      openAccessMenu,
      acionA,
      menuList,
      ...props
    } = this.props;
    const { isMenu } = this.state;
    let hasPermission = true;
    if (!isMenu) {
      hasPermission = true;
    }

    return (
      <Route
        {...props}
        render={(props) => {
          return hasPermission ? (
            <Component
              {...props}
              {...this.props}
              AuthBtn={this.state.authbtn}
            />
          ) : (
            <Page403 showHeader={false} {...props} />
          );
        }}
      />
    );
  }
}
export default AuthorizedRoute;
