import React from "react";
import { Menu, message, Tooltip } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import Initavatars from "../../assets/image/Initavatars.png";
import { isArray } from "../../utils/common";
import history from "../../api/history";
import utils from "../../utils/utils";
import api from "../../api/servers/login";

//退出登录
function Logout(props) {
  const userInfo = utils.get("userInfo");
  async function handleClick() {
    const res = await api.logout();
    if (res && res.errorCode == 200) {
      utils.clearUserInfo();
      utils.clear();
      history.push("/");
      message.success("退出成功");
    } else {
      // message.error(res.message);
    }
  }

  // 判断如果无权限的话没有rolePOList
  const userInfodiv = () => {
    return (
      userInfo &&
      isArray(userInfo.rolePOList) && (
        <span>{userInfo.rolePOList[0].roleName}</span>
      )
    );
  };

  return (
    <Menu>
      {/* <Menu.Item key="0">
        <div className="avatarsInfo">
          <img src={Initavatars} alt="" />
          <div className="span">
            <p>{userInfo ? userInfo.staffName : ""}</p>
            {isArray(userInfo.rolePOList) && (
              <p>
                <Tooltip
                  placement="topLeft"
                  title={userInfodiv()}
                >
                  {isArray(userInfo.rolePOList)
                    ? userInfodiv()
                    : ""}
                </Tooltip>
              </p>
            )}
          </div>
        </div>
      </Menu.Item> */}
      {/* <Menu.Divider /> */}
      <Menu.Item key="1" onClick={handleClick}>
        <div className="logoutinfo">
          <PoweroffOutlined
            style={{ fontSize: "16px", color: "#3978FC" }}
          />
          <span>退出当前账号</span>
        </div>
      </Menu.Item>
    </Menu>
  );
}

export default Logout;
