/*
 * @文档描述: 导出按钮组件
 * @author: 西园
 * @Date: 2020-06-11 15:51:29
 * @LastEditTime: 2020-08-11 17:15:17
 * @LastEditors: 陈金金
 * type： 按钮类型，primary dashed danger link
 * marginBottom： 距离底部的大小
 * params： 过滤条件
 * url： 请求地址
 */

import React from 'react';
import axios from 'axios';
import { Button, message } from 'antd';

class ExportBtn extends React.Component {
    state = {
        disabled: false
    };
    componentDidMount(){
       
    }
    handleClickExport = () => {
        console.log('21212')
        const { params, url } = this.props;
        const data = {};
        // 去除含有undefined的item
        Object.keys(params).forEach(res => {
            if (params[res]) {
                data[res] = params[res];
            }
        });
        this.setState(
            {
                disabled: true
            },
            () => {
                axios({
                    method: 'post',
                    url,
                    data:params,
                    responseType: 'blob'
                }).then(res => {
                    let headers = res.headers;
                    let contentType = headers['content-type'];
                    console.log(res)
                    if (!res.data) {
                        this.setState({
                            disabled: false
                        });
                        return false;
                    } else {
                        this.setState({
                            disabled: false
                        });
                        const blob = new Blob([res.data], { type: contentType });
                        let contentDisposition = res.headers['content-disposition'];
                        let fileName = 'unknown';
                        if (contentDisposition) {
                            fileName = window.decodeURIComponent(res.headers['content-disposition'].split('=')[1]);
                        }
                        if (fileName === 'unknown') {
                            message.destroy();
                            message.warning('导出失败！')
                            return false
                        }
                        // 非IE下载
                        if (window.navigator.msSaveOrOpenBlob) {
                            // IE10+下载
                            window.navigator.msSaveBlob(blob, fileName);
                        } else {
                            // 非IE下载
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob); // 创建下载的链接
                            link.download = fileName; // 下载后文件名
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click(); // 点击下载
                            window.URL.revokeObjectURL(link.href); // 释放掉blob对象
                            document.body.removeChild(link); // 下载完成移除元素
                        }
                    }
                });
            }
        );
    };
    render() {
        const { disabled } = this.state;
        const { marginBottom, type,text ,className} = this.props;
        return (
            <Button
                type={type || 'default'}
                className={className||""}
                style={{ marginBottom: marginBottom || 0 }}
                loading={disabled}
                onClick={()=>this.handleClickExport()}
            >
                {text}
            </Button>
        );
    }
}

export default ExportBtn;
