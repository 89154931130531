/*
 * @Description: 项目页面配置
 * @Author: 文倩
 * @Date: 2020-07-08 15:42:17
 * @LastEditTime: 2020-08-07 15:42:34
 * @LastEditor: 文倩
 */

import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "./App.less";
import history from "../src/api/history";
import HeaderComponent from "../src/components/header/header";
import BreadcrumbLists from "../src/components/header/Breadcrumb";
import menuList from "../src/routers/menu"; //mock菜单数据
import { systemIcon } from "./utils/contants";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import WorkbenchIcon from "./assets/image/Workbench.png";
import ProjectIcon from "./assets/image/Project.png";
import StatisticalReportIcon from "./assets/image/StatisticalReport.png";
import infoMaintenanceIcon from "./assets/image/infoMaintenance.png";
import SystemManagementIcon from "./assets/image/SystemManagement.png";

import WorkbenchActiveIcon from "./assets/image/WorkbenchActive.png";
import ProjectActiveIcon from "./assets/image/ProjectActive.png";
import StatisticalReportActiveIcon from "./assets/image/StatisticalReportActive.png";
import infoMaintenanceActiveIcon from "./assets/image/infoMaintenanceActive.png";
import SystemManagementActiveIcon from "./assets/image/SystemManagementActive.png";

// import { getMenuByUser } from '../src/actions/index';
import { map, cloneDeep } from "lodash";

import { Layout, Menu } from "antd";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const activeMenuColor = "rgba(51, 68, 255, 1)";
const subMenuColor = "rgba(65, 90, 124, 0.8)";
const menuColor = "rgba(28, 29, 31, 1)";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 菜单数据
      menuData: [],
      // 二级打开的菜单
      openKeys: [],
      //当前菜单
      current: "",
      // 当前才当keyPath
      curMenusKeyPath: [],
    };
  }
  componentDidMount() {
    const { location } = this.props;
    console.log("location --->", location);
    if (location && location.pathname) {
      //跳转详情页面时，菜单被选中
      const keyArry = location.pathname.split("/");
      console.log("keyArry --->", keyArry);
      const key = `/${keyArry[1]}/${keyArry[2]}`;
      this.setState({
        current: key,
        openKeys: [`/${keyArry[1]}`],
        curMenusKeyPath: ["", `/${keyArry[1]}`],
      });
    }

    this.getMenuByUser();
  }

  /**
   * @method 菜单加icon
   * @param { Object } data 菜单数据
   */
  addMenusIcon = (data) => {
    const cloneDeepData = cloneDeep(data);
    map(cloneDeepData, (item) => {
      switch (item.key) {
        case "/Workbench/list":
          item.icon = WorkbenchIcon;
          item.active_icon = WorkbenchActiveIcon;
          break;
        case "/Project/List":
          item.icon = ProjectIcon;
          item.active_icon = ProjectActiveIcon;
          break;
        case "/StatisticalReport":
          item.icon = StatisticalReportIcon;
          item.active_icon = StatisticalReportActiveIcon;
          break;
        case "/infoMaintenance":
          item.icon = infoMaintenanceIcon;
          item.active_icon = infoMaintenanceActiveIcon;
          break;
        case "/SystemManagement":
          item.icon = SystemManagementIcon;
          item.active_icon = SystemManagementActiveIcon;
          break;
        default:
          item.icon = undefined;
      }
    });
    return cloneDeepData;
  };
  //获取菜单权限
  getMenuByUser = async () => {
    const res = await this.$http.getMenuByUser();
    if (res && res.errorCode == 200) {
      if (this.$common.isArray(res.data)) {
        console.log("菜单权限 --->", res);
        const iconArr = this.addMenusIcon(res.data);
        console.log("iconArr --->", iconArr);
        this.setState({
          menuData: iconArr,
        });
        this.$utils.set("menuList", iconArr);
      } else {
        this.props.history.push("/Page403");
      }
    } else {
      this.$utils.clearUserInfo();
      this.$utils.clear();
      this.props.history.push("/");
    }
  };

  //点击当前菜单
  handleClick = (e) => {
    console.log("e --->", e);
    this.setState({
      current: e.key,
      curMenusKeyPath: e.keyPath,
    });
  };

  //展开菜单
  onOpenChange = (openKeys) => {
    console.log("openKeys --->", openKeys);
    this.setState({
      openKeys,
    });
  };

  render() {
    const { openKeys, current, menuData, curMenusKeyPath } = this.state;
    return (
      <Layout>
        <HeaderComponent
          refreshType={true}
          menuData={menuData}
          {...this.props}
          {...history}
        />
        <Layout>
          <Sider
            width="256"
            theme="light"
            className="silderLeft"
            style={{
              overflow: "auto",
              height: "calc(100vh - 64px)",
              position: "fixed",
              left: 0,
              zIndex: 998,
              top: "64px",
              padding: "24px 0",
            }}
          >
            <Menu
              theme="light"
              onClick={this.handleClick}
              selectedKeys={[current]}
              onOpenChange={this.onOpenChange}
              openKeys={openKeys}
              mode="inline"
            >
              {menuData &&
                menuData.length > 0 &&
                menuData.map((v) => {
                  console.log(v.icon);
                  return v && this.$common.isArray(v.siderMenu) && v.key ? (
                    <SubMenu
                      key={v.key}
                      title={
                        <p className="iconBox">
                          {/* {systemIcon.map((item, index) => {
                            return (
                              item.name == v.permissionName && (
                                
                              )
                            );
                          })} */}
                          <img
                            src={
                              v.key == curMenusKeyPath[1]
                                ? v.active_icon
                                : v.icon
                            }
                            className="sideMenuIcon"
                            alt="icon"
                          />
                          <span
                            style={{
                              color:
                                v.key == curMenusKeyPath[1]
                                  ? activeMenuColor
                                  : menuColor,
                              fontSize: 16,
                            }}
                          >
                            {v.permissionName}
                          </span>
                        </p>
                      }
                    >
                      {v.siderMenu.map((k) => {
                        return (
                          <Menu.Item key={k.key}>
                            <Link
                              to={k.key}
                              style={{
                                color:
                                  k.key == current
                                    ? activeMenuColor
                                    : subMenuColor,
                                fontSize: 16,
                              }}
                            >
                              {k.permissionName}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={v.key}>
                      <Link
                        to={v.key}
                        style={{
                          color: v.key == current ? activeMenuColor : menuColor,
                          fontSize: 16,
                        }}
                      >
                        <span className="iconBox">
                          {/* {systemIcon.map((item, index) => {
                            return (
                              item.name == v.permissionName && (
                                <img
                                  src={item.icon}
                                  className="sideMenuIcon"
                                  key={index}
                                  alt="icon"
                                />
                              )
                            );
                          })} */}
                          <img
                            src={v.key == current ? v.active_icon : v.icon}
                            className="sideMenuIcon"
                            alt="icon"
                          />
                          {v.permissionName}
                        </span>
                      </Link>
                    </Menu.Item>
                  );
                })}
            </Menu>
          </Sider>
          <Layout
            style={{
              position: "absolute",
              left: 256,
              right: 0,
              zIndex: 998,
              top: "64px",
              padding: "0 0  40px 0",
              overflowX: "auto",
            }}
          >
            <BreadcrumbLists style={{ margin: "16px 0" }} {...this.props} />
            <Content
              className="site-layout-background"
              style={{
                padding: "0 24px 24px",
                margin: 0,
                minHeight: 280,
                minWidth: 1200,
              }}
            >
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

// function mapStateToProps(state) {
// 	console.log(state, 8888);

// 	return {
// 		authBtn: state.menu
// 	}
// }

// function mapDispatchToProps(dispatch, props) {
// 	console.log(props, 12345);
// 	return {
// 		getMenuByUser: bindActionCreators(getMenuByUser, dispatch)
// 	}
// }

// connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
export default withRouter(App);
