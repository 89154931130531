/*
 * @Description: 人员基础信息调整
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 15:57:33
 */


import React, { Component } from 'react';
import { Button, Row, Col,Form, Modal, Radio, message } from 'antd';
import './Components.less'
import TooltipComponent from '../../../components/TooltipComponent'; // 文本溢出提示
import TextFieldInput from '../../../components/sceneInput/TextAreaInput';
class BasicInfoChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 3,
            btnLoading: false,
            visible: false,
            loading: false,
            pageNo: 1,
            pageSize: 10,
            isCard: false,
            searchValue: {},
            detailData: {},
            selectedRowKeys: []
        }
    }
    componentDidMount() {
        this.getDetail(this.props.businessKey)
    }
    /**
     * @description: 人员基础信息调整详情数据获取
     * @param {type} 
     * @return: 
     * @author: 陈金金
     */
    getDetail = (staffInfoAdjustId) => {
        this.setState({ Loading: true })
        this.$http.getStaffInfoAdjustById({ staffInfoAdjustId }).then(res => {
            this.setState({ Loading: false })
            if (res&&res.errorCode == 200) {
                const v = res.data;
                if (v.newBpoRankSalaryPO) {
                    v.cityNameAfter = v.newBpoRankSalaryPO.cityName
                    v.serveNatureAfter = v.newBpoRankSalaryPO.serveNatureValue
                    v.rankAfter = v.newBpoRankSalaryPO.rank
                }
                if (v.oldBpoRankSalaryPO) {
                    v.cityNameBefore = v.oldBpoRankSalaryPO.cityName
                    v.serveNatureBefore= v.oldBpoRankSalaryPO.serveNatureValue
                    v.rankBefore = v.oldBpoRankSalaryPO.rank
                }
                this.setState({
                    detailData: v,
                })
            }
        })
    }
    /**
     * @description:  人员基础信息调整审批
     * @param {type} Approval 1，通过，2 拒绝
     * @author: 陈金金
     */
    handleAddSure = () => {
        const { businessKey } = this.props;
        const { detailData } = this.state;
        this.props.form.validateFieldsAndScroll((err, v) => {
            this.setState({ btnLoading: true })
            this.$http[`${v.Approval == 'PASS' ? "staffInfoAdjustPass" : "staffInfoAdjustRefuse"}`]
                ({
                    staffInfoAdjustId: businessKey,
                    taskId: detailData.procStatusVO.taskId,
                    operDescription: v.operDescription
                }).then(res => {
                    this.setState({ btnLoading: false })
                    if (res&&res.errorCode == 200) {
                        message.destroy();
                        message.success(res.message);
                        this.props.closeModal(true)
                    }
                })

        });
    }

    handleReturnStockBtnCancle = () => {
        this.props.form.resetFields();
        this.setState({ visible: false })
        this.props.closeModal()
    }
    /**
* @description: 自动回显数据
* @return:
*/
    renderDetailKeyHTML = () => {
        const { detailData } = this.state;
        return [
            { id: 1, name: '调整人姓名：', key: 'staffName', span: 24 },
            { id: 2, name: '调整人工号：', key: 'staffCode', span: 24 },
            { id: 3, name: '调整类型：', key: 'adjustTypeStr', span: 24 },
            { id: 4, name: '当前身份：', key: 'adjustBefore', span: 12, type: 'IDENTITY_ADJUST' },
            { id: 5, name: '转正后工号：', key: 'adjustAfter', span: 12, type: 'IDENTITY_ADJUST' },
            { id: 6, name: '当前所属城市：', key: 'cityNameBefore', span: 12, type: 'OTHER_ADJUST' },
            { id: 7, name: '调整后：', key: 'cityNameAfter', span: 12, type: 'OTHER_ADJUST' },
            { id: 8, name: '当前服务性质：', key: 'serveNatureBefore', span: 12, type: 'OTHER_ADJUST' },
            { id: 9, name: '调整后：', key: 'serveNatureAfter', span: 12, type: 'OTHER_ADJUST' },
            { id: 10, name: '当前人员层级：', key: 'rankBefore', span: 12, type: 'OTHER_ADJUST' },
            { id: 11, name: '调整后：', key: 'rankAfter', span: 12, type: 'OTHER_ADJUST' },
            { id: 12, name: '调整原因说明', key: 'remarks', span: 12, block: true },
        ].map(item => {
            return (
                <Col span={item.span} key={item.id}>
                    {
                        item.type == undefined || (item.type && item.type == detailData.adjustType) ?
                            <div className="detailbox">
                                <span className="label" style={{ display: item.block ? 'block' : 'inline-block' }}>{item.name}</span>
                                <div className="value" style={{ marginTop: item.block ? '10px' : '' }}>
                                    {
                                        detailData[item.key] ?
                                            <TooltipComponent
                                                text={detailData[item.key]}
                                                line={item.key=='remarks'?2:undefined}
                                                width={item.key=='remarks'&& 400}
                                            /> : '--'
                                    }
                                </div>
                            </div> : null
                    }

                </Col>
            );
        });
    };
    render() {
        const { visible, procStatus,form } = this.props;
        const { getFieldDecorator } = this.props.form
        const { btnLoading, detailData } = this.state;
        return (
            <>
                {
                    visible && <Modal
                        className="SalaryChangeModal modalInfo"
                        title="人员基础信息调整"
                        visible={visible}
                        width={480}
                        footer={procStatus != 'AUDIT' ? false : [
                            <div key="BasicInfoChange">
                                <Button key="cancle" onClick={() => this.handleReturnStockBtnCancle()}>取消</Button>
                                <Button key="sure" onClick={this.handleAddSure} loading={btnLoading} type="primary" >提交 </Button>
                            </div>
                        ]}
                        onCancel={this.handleReturnStockBtnCancle}
                        maskClosable={false}
                    >
                        <div className='modalTitle'><span>申请详情</span></div>
                        <Row className='bottom30'>
                            {this.renderDetailKeyHTML()}
                        </Row>
                        {this.isArray(detailData.logVOS) && detailData.logVOS.map((v, i) => {
                            return <div className="logVOSList top50" key={v.taskDefKey}>
                                <div className="Divider"></div>
                                <div className='modalTitle top20' >
                                    <span>审批意见</span>
                                </div>
                                <Form className="top20">
                                    <Form.Item >
                                        <Radio.Group defaultValue={v.operResult}>
                                            <Radio disabled={v.operResult != 'PASS'} value={'PASS'}>同意</Radio>
                                            <Radio disabled={v.operResult != 'REFUSE'} value={'REFUSE'}>不同意</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="备注">
                                          <TooltipComponent
                                                text={v.operDescription}
                                                line={2}
                                                width={400}
                                            />
                                    </Form.Item>

                                </Form>
                            </div>
                        })
                        }
                        {
                            procStatus == 'AUDIT' &&
                            <div>
                                <div className="Divider"></div>
                                <div className='modalTitle top20'><span>审批意见</span></div>
                                <Form className="top20">

                                    <Form.Item >
                                        {getFieldDecorator('Approval', {
                                            initialValue: 'PASS',
                                        })(
                                            <Radio.Group >
                                                <Radio value={'PASS'}>同意</Radio>
                                                <Radio value={'REFUSE'}>不同意</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    <TextFieldInput
                                    form={form}
                                    showLength={true}
                                    label="备注"
                                    placeholder="请输入"
                                    allowClear
                                    field="operDescription"
                                    maxLength={500}
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                />
                                    {/* <Form.Item label="备注">
                                        {getFieldDecorator('operDescription')(
                                            <TextArea maxLength={500} row={4} />
                                        )}
                                    </Form.Item> */}
                                </Form>
                            </div>
                        }

                    </Modal>
                }
            </>
        );
    }
}

export default Form.create()(BasicInfoChange);