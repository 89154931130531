/*
 * @Description: 登录接口
 * @Author: 文倩
 * @Date: 2020-06-02 10:40:39
 * @LastEditTime: 2020-07-09 15:02:48
 * @LastEditor: 文倩
 */

import http from '../fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';


export default {
    //登录 
    login(param) {
        return http.post(`${baseUrl}/bpo/staff/login`, param);
    },
    //退出
    logout(param) {
        return http.post(`${baseUrl}/bpo/staff/logout`, param);
    },
}