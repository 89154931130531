import CryptoJS from "crypto-js";
const CRYPTOJSKEY= "cBssbHB3ZA==HKXT";

const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF");  
    const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');
/**
 * 封装localStorage
 * 增加对JSON对象的转换
 * @author: Ziv
 * @return {[type]} [description]
 */
const localDb = {
    /**
     * 按key存贮数据value到localStorage
     * @param {String} key   存贮数据的唯一标识
     * @param {String, Object} value 所要存贮的数据
     */
    set(key, value) {
        if (!value) delete window.localStorage[key]
        else {
            const val = typeof value === 'object' ?
            JSON.stringify(value) : value
            window.localStorage[key] = val
        }
    },


    /**
     * 通过key从localStorage获取数据
     * @param  {String} key  获取数据的可以标识
     * @return {String, Object}  返回空，字符串或者对象
     */
    get(key) {
        const str = window.localStorage[key] || ''
        return this.isJSONStr(str) ? JSON.parse(str) : str
    },

    /**
     * 判断是否是JSON string
     * @param  {String}  str 所要验证的字符串
     * @return {Boolean}   是否是JSON字符串
     */
    isJSONStr(str) {
        return (
            (str.charAt(0) === '{' && str.charAt(str.length - 1) === '}') ||
            (str.charAt(0) === '[' && str.charAt(str.length - 1) === ']')
        )
    },

    

    // 设置用户信息
    setUserInfo(userInfoKey, data, persistent =false) {
        if (persistent) {
            this.set(userInfoKey, data)
        }else {
            sessionStorage.setItem(userInfoKey, JSON.stringify(data))
        }
    },
    // 获得用户信息
    getUserInfo(userInfoKey, persistent =false) {
        if (persistent) {
            return this.get(userInfoKey)

        }else {
            const str = window.sessionStorage[userInfoKey] || ''
            return this.isJSONStr(str) ? JSON.parse(str) : str
        }
    },

    //清空用户信息
    clearUserInfo(userInfoKey, persistent =false){
        if(persistent){
            return this.clear(userInfoKey)
        }else if(userInfoKey){
            window.sessionStorage.removeItem(userInfoKey)
        }else{
            // window.sessionStorage.clear()
            window.localStorage.removeItem('menuList')
            window.localStorage.removeItem('userInfo')
        }
    },

    /**
     * 清空localStorage
     * @return 无返回NULL
     */
    clear(key) {
        if(key){
            window.localStorage.removeItem('key')
        }else{
            window.localStorage.removeItem('menuList')
            window.localStorage.removeItem('userInfo')
        }   
        
    },
    // 加密
    encrypt(plaintText) {
        var plaintTextCopy = plaintText;
        var options = {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        };
        var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY);
        var encryptedData = CryptoJS.AES.encrypt(plaintTextCopy, key, options);
        var encryptedBase64Str = encryptedData.toString()
        return encryptedBase64Str;
    },
    //解密
    decrypt(encryptedBase64Str) {
        var vals = encryptedBase64Str && encryptedBase64Str.replace(/\\-/g, '+').replace(/_/g, '/');
        var options = {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        };
        var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY);
        var decryptedData = CryptoJS.AES.decrypt(vals, key, options);
        var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData);
        return decryptedStr
    }
}

export default localDb
