/*
 * @Description: 薪资结构调整
 * @Version: 2.0
 * @Autor: 陈金金
 * @Date: 2020-07-16 15:18:49
 * @LastEditors: 陈金金
 * @LastEditTime: 2020-10-22 16:18:26
 */

import React, { Component } from "react";
import { Button, Row, Col, Form, Modal, Radio, message } from "antd";
import "./Components.less";
import TooltipComponent from "../../../components/TooltipComponent"; // 文本溢出提示
import TextFieldInput from "../../../components/sceneInput/TextAreaInput";
import { cloneDeep, split, zipObject } from "lodash";
class SalaryChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 3,
      visible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      isCard: false,
      searchValue: {},
      detailData: {},
      btnLoading: false,
      selectedRowKeys: [],
    };
  }
  componentDidMount() {
    this.getDetail(this.props.businessKey);
  }

  /**
   * ***************************************
   * 如果调整类型是 非系统工资变动            *
   * 给detailData添加6个字段,更新detailData  *
   * ***************************************
   * nonSystemFixedSalaryIncreaseBefore
   * nonSystemFixedSalaryIncrease
   * personalPerformanceBenchmarksBefore
   * personalPerformanceBenchmarks
   * projectPerformancePoolBenchmarksBefore
   * projectPerformancePoolBenchmarks
   * @param { Object } detailData 详情数据
   */
  checkAdjustmentType = (detailData) => {
    const { adjustType, adjustAfter, adjustBefore } = detailData;
    if (adjustType === "PERFORMANCE_STANDARD") {
      // 修改之后字符串转数组
      const nonSystematicWageChangesAfterArr = split(adjustAfter, ",", 3);
      // 修改之前字符串转数组
      const nonSystematicWageChangesBeforeArr = split(adjustBefore, ",", 3);
      const afterObject = zipObject(
        [
          "nonSystemFixedSalaryIncrease",
          "personalPerformanceBenchmarks",
          "projectPerformancePoolBenchmarks",
        ],
        nonSystematicWageChangesAfterArr
      );
      const beforeObject = zipObject(
        [
          "nonSystemFixedSalaryIncreaseBefore",
          "personalPerformanceBenchmarksBefore",
          "projectPerformancePoolBenchmarksBefore",
        ],
        nonSystematicWageChangesBeforeArr
      );
      const copyDetailData = { ...detailData, ...afterObject, ...beforeObject };
      this.setState({
        detailData: copyDetailData,
      });
      console.log("checkAdjustmentType --->", copyDetailData);
    }
  };

  /**
   * @description: 薪资结构调整情数据获取
   * @param {type}
   * @return:
   * @author: 陈金金
   */
  getDetail = (salaryAdjustId) => {
    this.setState({ Loading: true });
    this.$http.getSalaryAdjustById({ salaryAdjustId }).then((res) => {
      this.setState({ Loading: false });
      if (res && res.errorCode == 200) {
        this.setState(
          {
            detailData: res.data,
          },
          () => {
            this.checkAdjustmentType(res.data);
          }
        );
      }
    });
  };
  /**
   * @description:  薪资结构调整
   * @param {type} Approval 1，通过，2 拒绝
   * @author: 陈金金
   */
  handleAddSure = () => {
    const { businessKey } = this.props;
    const { detailData } = this.state;
    const {
      adjustType,
      nonSystemFixedSalaryIncrease,
      personalPerformanceBenchmarks,
      projectPerformancePoolBenchmarks,
    } = detailData;
    let nonSystematicWageChanges = {};
    if (adjustType === "PERFORMANCE_STANDARD") {
      nonSystematicWageChanges = {
        nonSystemFixedSalaryIncrease,
        personalPerformanceBenchmarks,
        projectPerformancePoolBenchmarks,
      };
    }
    console.log("nonSystematicWageChanges --->", nonSystematicWageChanges);
    this.props.form.validateFieldsAndScroll((err, v) => {
      console.log(v);
      this.setState({ btnLoading: true });
      this.$http[
        `${v.Approval == "PASS" ? "salaryAdjustPass" : "salaryAdjustRefuse"}`
      ]({
        salaryAdjustId: businessKey,
        taskId: detailData.procStatusVO.taskId,
        operDescription: v.operDescription,
        ...nonSystematicWageChanges,
      })
        .then((res) => {
          this.setState({ btnLoading: false });
          if (res && res.errorCode == 200) {
            message.destroy();
            message.success(res.message);
            this.props.closeModal(true);
          }
        })
        .catch(() => {
          this.setState({ btnLoading: false });
        });
    });
  };

  handleReturnStockBtnCancle = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
    this.props.closeModal();
  };

  /**
   * **********************
   * 只显示修改过的值      *
   * 所有可能性 :          *
   * "1,2,3" ---> "4,5,6" *
   * "1,,"   ---> "4,,"   *
   * "1,,3"  ---> "4,,6"  *
   * "1,2,"  ---> "4,5,"  *
   * **********************
   * @method 获取非系统工资变动要展示的数据
   * @param { String } adjustBefore 调整前或者调整后的值
   */
  getNonSystematicWageChangesColumnData = (adjustBefore) => {
    const nonSystematicWageChangesBeforeArr = split(adjustBefore, ",", 3);
    const nonSystemFixedSalaryIncreaseArr = nonSystematicWageChangesBeforeArr[0]
      ? [
          {
            id: 8,
            name: "原非系统固定涨薪：",
            key: "nonSystemFixedSalaryIncreaseBefore",
            span: 12,
            type: "PERFORMANCE_STANDARD",
          },
          {
            id: 9,
            name: "调整后：",
            key: "nonSystemFixedSalaryIncrease",
            span: 12,
            type: "PERFORMANCE_STANDARD",
          },
        ]
      : [];

    const personalPerformanceBenchmarksArr = nonSystematicWageChangesBeforeArr[1]
      ? [
          {
            id: 10,
            name: "原个人绩效基准值：",
            key: "personalPerformanceBenchmarksBefore",
            span: 12,
            type: "PERFORMANCE_STANDARD",
          },
          {
            id: 11,
            name: "调整后：",
            key: "personalPerformanceBenchmarks",
            span: 12,
            type: "PERFORMANCE_STANDARD",
          },
        ]
      : [];

    const projectPerformancePoolBenchmarksArr = nonSystematicWageChangesBeforeArr[2]
      ? [
          {
            id: 12,
            name: "原项目绩效池基准值：",
            key: "projectPerformancePoolBenchmarksBefore",
            span: 12,
            type: "PERFORMANCE_STANDARD",
          },
          {
            id: 13,
            name: "调整后：",
            key: "projectPerformancePoolBenchmarks",
            span: 12,
            type: "PERFORMANCE_STANDARD",
          },
        ]
      : [];
    let nonSystematicWageChangesData = [
      ...nonSystemFixedSalaryIncreaseArr,
      ...personalPerformanceBenchmarksArr,
      ...projectPerformancePoolBenchmarksArr,
    ];
    return nonSystematicWageChangesData;
  };

  /**
   * @description: 自动回显数据
   * @return:
   */
  renderDetailKeyHTML = () => {
    const { detailData } = this.state;
    const { adjustType, adjustAfter, adjustBefore } = detailData;
    let nonSystematicWageChanges =
      adjustType === "PERFORMANCE_STANDARD"
        ? this.getNonSystematicWageChangesColumnData(adjustBefore)
        : [];

    return [
      { id: 1, name: "调整人姓名：", key: "staffName", span: 24 },
      { id: 2, name: "调整人工号：", key: "staffCode", span: 24 },
      { id: 3, name: "调整类型：", key: "adjustTypeStr", span: 24 },
      {
        id: 4,
        name: "原系统薪资：",
        key: "adjustBefore",
        span: 12,
        type: "SALARY_ADJUST",
      },
      {
        id: 5,
        name: "调整后：",
        key: "adjustAfter",
        span: 12,
        type: "SALARY_ADJUST",
      },
      {
        id: 6,
        name: "原付费类型：",
        key: "adjustBefore",
        span: 12,
        type: "PAY_TYPE",
      },
      {
        id: 7,
        name: "调整后：",
        key: "adjustAfter",
        span: 12,
        type: "PAY_TYPE",
      },
      // {
      //   id: 8,
      //   name: "原绩效考核基准值：",
      //   key: "adjustBefore",
      //   span: 12,
      //   type: "PERFORMANCE_STANDARD",
      // },
      // {
      //   id: 9,
      //   name: "调整后：",
      //   key: "adjustAfter",
      //   span: 12,
      //   type: "PERFORMANCE_STANDARD",
      // },
      ...nonSystematicWageChanges,
      { id: 10, name: "调整原因说明", key: "remarks", span: 12, block: true },
    ].map((item) => {
      return (
        <Col span={item.span} key={item.id}>
          {item.type == undefined ||
          (item.type && item.type == detailData.adjustType) ? (
            <div className="detailbox">
              <span
                className="label"
                style={{ display: item.block ? "block" : "inline-block" }}
              >
                {item.type
                  ? item.type == detailData.adjustType
                    ? item.name
                    : undefined
                  : item.name}
              </span>
              <div
                className="value"
                style={{ marginTop: item.block ? "10px" : "" }}
              >
                {detailData[item.key] ? (
                  <TooltipComponent
                    text={detailData[item.key]}
                    line={item.key == "remarks" ? 2 : undefined}
                    width={item.key == "remarks" && 400}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </Col>
      );
    });
  };
  render() {
    const { visible, procStatus, form } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { btnLoading, detailData } = this.state;
    return (
      <>
        {visible && (
          <Modal
            className="SalaryChangeModal modalInfo"
            title="薪资结构调整"
            visible={visible}
            width={480}
            footer={
              procStatus != "AUDIT"
                ? false
                : [
                    <div key="SalaryChangeModal">
                      <Button
                        key="cancle"
                        onClick={() => this.handleReturnStockBtnCancle()}
                      >
                        取消
                      </Button>
                      <Button
                        key="sure"
                        onClick={this.handleAddSure}
                        loading={btnLoading}
                        type="primary"
                      >
                        提交{" "}
                      </Button>
                    </div>,
                  ]
            }
            onCancel={this.handleReturnStockBtnCancle}
            maskClosable={false}
          >
            <div className="modalTitle">
              <span>申请详情</span>
            </div>
            <Row className="bottom50">{this.renderDetailKeyHTML()}</Row>

            {this.isArray(detailData.logVOS) &&
              detailData.logVOS.map((v, i) => {
                return (
                  <div className="logVOSList top50" key={v.taskDefKey}>
                    <div className="Divider"></div>
                    <div className="modalTitle top20">
                      <span>审批意见</span>
                    </div>
                    <Form className="top20">
                      <Form.Item>
                        <Radio.Group defaultValue={v.operResult}>
                          <Radio
                            disabled={v.operResult != "PASS"}
                            value={"PASS"}
                          >
                            同意
                          </Radio>
                          <Radio
                            disabled={v.operResult != "REFUSE"}
                            value={"REFUSE"}
                          >
                            不同意
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="备注">
                        <TooltipComponent
                          text={v.operDescription}
                          line={2}
                          width={400}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                );
              })}
            {procStatus == "AUDIT" && (
              <div>
                <div className="Divider"></div>
                <div className="modalTitle top20">
                  <span>审批意见</span>
                </div>
                <Form className="top20">
                  <Form.Item>
                    {getFieldDecorator("Approval", {
                      initialValue: "PASS",
                    })(
                      <Radio.Group>
                        <Radio value={"PASS"}>同意</Radio>
                        <Radio value={"REFUSE"}>不同意</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <TextFieldInput
                    form={form}
                    showLength={true}
                    label="备注"
                    placeholder="请输入"
                    allowClear
                    field="operDescription"
                    maxLength={500}
                    autoSize={{ minRows: 4, maxRows: 5 }}
                  />
                  {/* <Form.Item label="备注">
                                    {getFieldDecorator('operDescription')(
                                        <TextArea maxLength={500} row={4}/>
                                    )}
                                </Form.Item> */}
                </Form>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default Form.create()(SalaryChange);
